import React, { useState, useEffect } from 'react'
import { NavBar } from '../../../components/NavBar/NavBar'
import { AppDispatch, RootState } from 'store'
import {
  ContentWrapper,
  Flex,
  H1WithBack,
  PageWrapper,
  Tabs,
  TabsContainer,
} from '../../../styling/GlobalStyles'
import { InventoryItemsSpecificationTab } from './InventoryItemsSpecificationTab'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearInventoryItem,
  getSingleInventoryItem,
  updateInventoryItem,
} from 'features/inventory/inventoryItemsSlice'
import { useNavigate, useParams } from 'react-router-dom'
import {
  clearInventoryCategory,
  getSingleInventoryCategory,
} from 'features/inventory/invSettingsCategoriesSlice'
import { InventoryItemsMachineryTab } from './InventoryItemsMachineryTab'
import { IInventoryItem } from 'types/inventory.interface'
import { toast } from 'react-toastify'
import { InventoryItemsCapabilitiesTab } from './InventoryItemsCapabilitiesTab'
import { isEmpty } from 'utils/helpers'

export const EditItem = () => {
  const [active, setActive] = useState('Specification')
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams()
  const { singleInventoryItem } = useSelector((store: RootState) => store.inventoryItems)
  const [invalidCategoryFields, setInvalidCategoryFields] = useState<string[]>([])
  const navigate = useNavigate()
  const [invalidFields, setInvalidFields] = useState([])
  const [state, setState] = useState<IInventoryItem>(null)

  useEffect(() => {
    dispatch(getSingleInventoryItem(id))
    return () => {
      dispatch(clearInventoryItem())
    }
  }, [])

  useEffect(() => {
    state?.category && dispatch(getSingleInventoryCategory(state?.category?.id))
    return () => {
      dispatch(clearInventoryCategory())
    }
  }, [state?.category.id])

  useEffect(() => {
    if (singleInventoryItem && !isEmpty(singleInventoryItem)) {
      const obj = structuredClone(singleInventoryItem)
      setState(obj)
    }
  }, [singleInventoryItem])

  const handleSubmit = async () => {

    if (invalidFields.length > 1 && invalidCategoryFields.length > 1) {
      toast.warn('All specification fields must be valid')
      return
    }

    const resp = await dispatch(updateInventoryItem(state))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/inventory-items')
    }
  }

  const renderTabs = () => {
    if (active === 'Specification') {
      return (
        <InventoryItemsSpecificationTab
          state={state}
          setState={setState}
          setActive={setActive}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          invalidCategoryFields={invalidCategoryFields}
          setInvalidCategoryFields={setInvalidCategoryFields}
        />
      )
    } else if (active === 'Machines') {
      return (
        <InventoryItemsMachineryTab
          state={state}
          setState={setState}
          handleSubmit={handleSubmit}
          setActive={setActive}
        />
      )
    } else if (active === 'Capabilities') {
      return (
        <InventoryItemsCapabilitiesTab
          state={state}
          setState={setState}
          setActive={setActive}
          handleSubmit={handleSubmit}
        />
      )
    }
  }

  return (
    <>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack
            mb='1.5'
            arrow
            title={'Edit ' + (singleInventoryItem?.name ? singleInventoryItem?.name : '')}
          />
          <TabsContainer>
            <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
              Specification
            </Tabs>
            <Tabs active={active === 'Machines'} onClick={() => setActive('Machines')}>
              Machines
            </Tabs>
            <Tabs active={active === 'Capabilities'} onClick={() => setActive('Capabilities')}>
              Capabilities
            </Tabs>
          </TabsContainer>
        </Flex>
        <Flex between></Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </>
  )
}
