/* eslint-disable @typescript-eslint/no-unused-vars */
import { NavBar } from 'components/NavBar/NavBar'
import {
    Button,
    ClientDashboardContainer,
    Flex,
    MainHeadline,
    PageWrapper,
} from 'styling/GlobalStyles'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { getDashboardData } from 'features/dashboard/dashboardSlice'
import { WidgetsSidebar } from 'components/Dashboard/WidgetsSidebar'
import { PlaceholderContainer, WidgetsGrid } from './Dashboard.styled'
import { setState } from 'utils/helpers'
import { initialAreas, initialPresentWidgets } from './dashboardData'
import { InventoryWidget } from './Widgets/InventoryWidget'
import { CyclesWidget } from './Widgets/CyclesWidget'
import { FinancesWidget } from './Widgets/FinancesWidget'
import { WorkersWidget } from './Widgets/WorkersWidget'
import { AlertsWidget } from './Widgets/AlertsWidget'
import { MachineryWidget } from './Widgets/MachineryWidget'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { CSSProperties } from 'styled-components'
import { TasksWidget } from './Widgets/TasksWidget'

export const ClientDashboard = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [modalOpen, setModalOpen] = useState(false)
    const [presentWidgets, setPresentWidgets] = useState(initialPresentWidgets)
    const [dragging, setDragging] = useState({ width: 0, type: '', overArea: '' })
    const [areas, setAreas] = useState(initialAreas)
    const [draggingPresent, setDraggingPresent] = useState({
        type: '',
        overType: '',
        overPh: '',
        width: 1
    })
    const gridTemplateAreas = useMemo(
        () => `
        "${areas[1]} ${areas[2]} ${areas[3]} ${areas[4]}"
        "${areas[5]} ${areas[6]} ${areas[7]} ${areas[8]}"
    `, [areas]
    )

    useEffect(() => {
        const a = localStorage.getItem('dashboardAreas')
        const p = localStorage.getItem('presentWidgets')

        if (a) {
            setAreas(JSON.parse(a))
        }
        if (p) {
            setPresentWidgets(JSON.parse(p))
        }
        dispatch(getDashboardData())
    }, [])


    const Placeholder = ({ gridArea }: { gridArea: string }) => {
        const widthError =
            (draggingPresent.width > 1 || (dragging.width > 1)) &&
            (gridArea == '4' || gridArea == '8' || (!areas[Number(gridArea) + 1].includes('ph') && !(areas[Number(gridArea) + 1] == draggingPresent.type)))

        const [isDraggedOver, setIsDraggedOver] = useState(false)
        const [isForbidden, setIsForbidden] = useState(widthError)
        const styles: CSSProperties = isDraggedOver
            ? isForbidden
                ? { background: '#ff00001b', border: '1px dashed red' }
                : { background: '#00ff041b', border: '1px dashed green' }
            : modalOpen
                ? { border: '1px dashed grey' }
                : {}

        const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()


            if (draggingPresent.type && !draggingPresent.overPh) {
                setState(setDraggingPresent, 'overPh', gridArea)
                setIsDraggedOver(true)
                return
            }

            if (dragging.overArea != gridArea) {
                setState(setDragging, 'overArea', gridArea)
            }
            if (widthError) {
                setIsForbidden(true)
            }
            setIsDraggedOver(true)
        }

        const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()
            setIsDraggedOver(false)
        }

        const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()
            setIsDraggedOver(false)
            setIsForbidden(false)


            if (draggingPresent.type) {
                if (isForbidden) {
                    return
                }
                setAreas((prev) => {
                    const copy = { ...prev }
                    Object.keys(copy).forEach(k => {
                        if (copy[k] === draggingPresent.type) {
                            copy[k] = `ph-${k}`
                        }
                    })
                    copy[gridArea] = draggingPresent.type
                    if (draggingPresent.width > 1) {
                        copy[Number(gridArea) + 1] = draggingPresent.type
                    }
                    return copy
                })
                setDraggingPresent({
                    type: '',
                    overType: '',
                    overPh: '',
                    width: 1
                })
                return

            }
            if (
                dragging.width > 1 &&
                (gridArea == '4' || gridArea == '8' || !areas[Number(gridArea) + 1].includes('ph'))
            ) {
                return
            }

            setDragging({ width: 0, overArea: '', type: '' })

            setAreas((prev) => {
                const obj = {
                    ...prev,
                    [dragging.overArea]: dragging.type,
                }

                if (dragging.width > 1) {
                    const int = Number(dragging.overArea)
                    obj[String(int + 1)] = dragging.type
                }
                return obj
            })
            setPresentWidgets((prev) => {
                prev.push(dragging.type)
                return prev
            })
        }

        return (
            <PlaceholderContainer
                style={{ gridArea: 'ph-' + gridArea, ...styles }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {!isForbidden && modalOpen && <p>Drop here</p>}
                {isForbidden && <CloseIcon red />}
            </PlaceholderContainer>
        )
    }

    const saveLayout = () => {
        localStorage.setItem('dashboardAreas', JSON.stringify(areas))
        localStorage.setItem('presentWidgets', JSON.stringify(presentWidgets))
    }

    const isEmpty = (num: string) => {
        return areas[num].includes('ph')
    }

    const present = (type: string) => {
        return presentWidgets?.includes(type)
    }

    const removeWidget = (widget: string) => {
        setAreas((prev) => {
            const copy = { ...prev }
            const keys = Object.keys(copy)
            const indexes = []
            indexes.push(...keys.filter(k => prev[k] === widget))
            indexes.forEach(i => copy[i] = 'ph-' + i)
            return copy
        })
        if (draggingPresent.type == widget) {
            setDraggingPresent(prev => ({ ...prev, type: '' }))
        }


        setPresentWidgets((prev) => prev.filter((w) => w !== widget))
    }

    const onWidgetDragStart = (type: string, width: number) => {
        setDraggingPresent({ type, overType: '', overPh: '', width })
    }

    const onWidgetDragDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDraggingPresent({
            type: '',
            overType: '',
            overPh: '',
            width: 1
        })
        return
    }

    const onWidgetDragOver = (type: string) => {
        if (type == 'alerts') {
            return
        }
        if (draggingPresent.type == type) {
            return
        }
        if (draggingPresent.overType != type) {
            setDraggingPresent(prev => ({ ...prev, overType: type }))
        }
        if (draggingPresent.type && draggingPresent.overType == type) {
            setAreas((prev) => {
                const copy = { ...prev }
                const widgetToMoveIndex = Object.keys(copy).find(key => copy[key] === type)
                const draggingWidgetNewIndex = Object.keys(copy).find(key => copy[key] === draggingPresent.type)
                if (draggingPresent.width > 1) {
                    if ([3, 7].includes(Object.keys(copy).findIndex(key => copy[key] === type))) {
                        return copy
                    }
                    const secondElementToMoveType = copy[Number(widgetToMoveIndex) + 1]
                    if (secondElementToMoveType == draggingPresent.type) {
                        copy[Number(widgetToMoveIndex) + 2] = type
                        copy[widgetToMoveIndex] = draggingPresent.type
                        copy[Number(widgetToMoveIndex) + 1] = draggingPresent.type
                        return copy
                    }
                    copy[widgetToMoveIndex] = draggingPresent.type
                    copy[Number(widgetToMoveIndex) + 1] = draggingPresent.type
                    copy[draggingWidgetNewIndex] = type
                    copy[Number(draggingWidgetNewIndex) + 1] = secondElementToMoveType.includes('ph') ? 'ph-' + (Number(draggingWidgetNewIndex) + 1) : secondElementToMoveType
                    return copy
                }

                if (widgetToMoveIndex && draggingWidgetNewIndex) {
                    const freePhIndex = Object.keys(copy).find(key => copy[key].includes('ph'))
                    if (freePhIndex) {
                        copy[freePhIndex] = type
                        copy[widgetToMoveIndex] = draggingPresent.type
                        copy[draggingWidgetNewIndex] = 'ph-' + draggingWidgetNewIndex
                        return copy
                    }
                    copy[widgetToMoveIndex] = draggingPresent.type
                    copy[draggingWidgetNewIndex] = type
                }
                return copy
            })
        }

    }
    return (
        <>
            <NavBar />
            <PageWrapper noFooter>
                <Flex center>
                    <MainHeadline flex w='100%'>
                        Dashboard
                    </MainHeadline>
                    <Button
                        orange
                        onClick={() => {
                            modalOpen && saveLayout()
                            setModalOpen(!modalOpen)
                        }
                        }
                    >
                        {modalOpen ? 'Save' : 'Edit'} Widgets
                    </Button>
                </Flex>

                <ClientDashboardContainer style={{ paddingBottom: '1rem' }}>
                    <WidgetsGrid style={{ gridTemplateAreas }}>
                        {isEmpty('1') && <Placeholder gridArea='1' />}
                        {isEmpty('2') && <Placeholder gridArea='2' />}
                        {isEmpty('3') && <Placeholder gridArea='3' />}
                        {isEmpty('4') && <Placeholder gridArea='4' />}
                        {isEmpty('5') && <Placeholder gridArea='5' />}
                        {isEmpty('6') && <Placeholder gridArea='6' />}
                        {isEmpty('7') && <Placeholder gridArea='7' />}
                        {isEmpty('8') && <Placeholder gridArea='8' />}
                        {present('tasks') && (
                            <TasksWidget
                                onDragOver={onWidgetDragOver}
                                setDragging={setDragging}
                                removeWidget={removeWidget}
                                modalOpen={modalOpen}
                                onDragStart={onWidgetDragStart}
                                onDragDrop={onWidgetDragDrop}
                            />
                        )}
                        {present('inventory') && (
                            <InventoryWidget
                                onDragOver={onWidgetDragOver}
                                setDragging={setDragging}
                                removeWidget={removeWidget}
                                modalOpen={modalOpen}
                                onDragStart={onWidgetDragStart}
                                onDragDrop={onWidgetDragDrop}
                            />
                        )}
                        {present('cycles') && (
                            <CyclesWidget
                                onDragOver={onWidgetDragOver}
                                setDragging={setDragging}
                                removeWidget={removeWidget}
                                modalOpen={modalOpen}
                                onDragStart={onWidgetDragStart}
                                onDragDrop={onWidgetDragDrop}
                            />
                        )}
                        {present('finances') && (
                            <FinancesWidget
                                onDragOver={onWidgetDragOver}
                                setDragging={setDragging}
                                removeWidget={removeWidget}
                                modalOpen={modalOpen}
                                onDragStart={onWidgetDragStart}
                                onDragDrop={onWidgetDragDrop}
                            />
                        )}
                        {present('workers') && (
                            <WorkersWidget
                                onDragOver={onWidgetDragOver}
                                setDragging={setDragging}
                                removeWidget={removeWidget}
                                modalOpen={modalOpen}
                                onDragStart={onWidgetDragStart}
                                onDragDrop={onWidgetDragDrop}
                            />
                        )}
                        {present('alerts') && (
                            <AlertsWidget
                                onDragOver={onWidgetDragOver}
                                setDragging={setDragging}
                                removeWidget={removeWidget}
                                modalOpen={modalOpen}
                                onDragStart={onWidgetDragStart}
                                onDragDrop={onWidgetDragDrop}
                            />
                        )}
                        {present('machinery') && (
                            <MachineryWidget
                                onDragOver={onWidgetDragOver}
                                setDragging={setDragging}
                                removeWidget={removeWidget}
                                modalOpen={modalOpen}
                                onDragStart={onWidgetDragStart}
                                onDragDrop={onWidgetDragDrop}
                            />
                        )}

                    </WidgetsGrid>
                    <WidgetsSidebar
                        saveLayout={saveLayout}
                        presentWidgets={presentWidgets}
                        setDragging={setDragging}
                        open={modalOpen}
                        setOpen={setModalOpen}
                    />
                </ClientDashboardContainer>
            </PageWrapper>
        </>
    )
}
