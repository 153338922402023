import React, { useState, useEffect } from 'react'
import { NavBar } from '../../../components/NavBar/NavBar'
import { AppDispatch } from 'store'

import {
  ContentWrapper,
  Flex,
  H1WithBack,
  PageWrapper,
  Tabs,
  TabsContainer,
} from '../../../styling/GlobalStyles'
import { InventoryItemsSpecificationTab } from './InventoryItemsSpecificationTab'
import { useDispatch } from 'react-redux'
import { IInventoryItem } from 'types/inventory.interface'
import { addInventoryItem } from 'features/inventory/inventoryItemsSlice'
import { getLocations } from 'features/locations/locationsSlice'
import {
  clearInventoryCategory,
  getInventoryCategories,
} from 'features/inventory/invSettingsCategoriesSlice'
import { InventoryItemsMachineryTab } from './InventoryItemsMachineryTab'
import { getClientMachinery } from 'features/clientMachinery/clientMachinerySlice'
import { useNavigate } from 'react-router-dom'
import { InventoryItemsCapabilitiesTab } from './InventoryItemsCapabilitiesTab'
import { itemsValidationList } from './itemsValidation'
import { toast } from 'react-toastify'

export const AddItem = () => {
  const [active, setActive] = useState('Specification')
  const [invalidFields, setInvalidFields] = useState(itemsValidationList)
  const [invalidCategoryFields, setInvalidCategoryFields] = useState<string[]>([])
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [state, setState] = useState<IInventoryItem>({
    name: '',
    quantity: null,
    minLimit: null,
    maxLimit: null,
    category: { id: null, name: '' },
    location: { id: null, name: '', quantity: 0 },
    currentLocations: [],
    machineModels: [],
    operations: [],
    sku: '',
    model: '',
    fieldValues: [],
    price: null,
  })
  const handleSubmit = async () => {

    if (invalidFields.length > 0 && invalidCategoryFields.length > 0) {
      toast.warn('All specification fields must be valid')
      return
    }

    const resp = await dispatch(addInventoryItem(state))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/inventory-items')
    }
  }
  const renderTabs = () => {
    if (active === 'Specification') {
      return (
        <InventoryItemsSpecificationTab
          state={state}
          setState={setState}
          setActive={setActive}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          invalidCategoryFields={invalidCategoryFields}
          setInvalidCategoryFields={setInvalidCategoryFields}
        />
      )
    } else if (active === 'Machines') {
      return (
        <InventoryItemsMachineryTab
          state={state}
          handleSubmit={handleSubmit}
          setState={setState}
          setActive={setActive}
        />
      )
    } else if (active === 'Capabilities') {
      return (
        <InventoryItemsCapabilitiesTab
          state={state}
          setState={setState}
          setActive={setActive}
          handleSubmit={handleSubmit}
        />
      )
    }
  }

  useEffect(() => {
    dispatch(getInventoryCategories(null))
    dispatch(getLocations(null))
    dispatch(getClientMachinery(null))

    return () => {
      dispatch(clearInventoryCategory())
    }
  }, [])

  return (
    <>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack arrow title='Add Item' />
          <TabsContainer>
            <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
              Specification
            </Tabs>
            <Tabs active={active === 'Machines'} onClick={() => setActive('Machines')}>
              Machines
            </Tabs>
            <Tabs active={active === 'Capabilities'} onClick={() => setActive('Capabilities')}>
              Capabilities
            </Tabs>
          </TabsContainer>
        </Flex>
        <Flex between></Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </>
  )
}
