import { baseUrl } from '../../utils/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { IDepartmentsGetAll, IDepartmentsSlice, IDepartmentsState } from 'types/departments.interface'
import { handleErrors, formatParams } from 'utils/helpers'
import { INameId } from 'constants/globalTypes'
import { IDepartmentsPostPatchRequest, IDepratmnetsGetSingleResponse } from 'types/api/departmentsApi.interface'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'

const initialState:IDepartmentsSlice = {
  isLoading: false,
  departments: [],
  singleDepartment: null,
  noDepartments: false,
  totalCount: null,
  totalPages: null,
}

export const getDepartments = createAsyncThunk(
  'departments/getDepartments',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp: IDepartmentsGetAll = await baseUrl.get('departments', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addDepartment = createAsyncThunk(
  'departments/addDepartment',
  async (dep: IDepartmentsState, thunkAPI) => {
    try {
      const objForDispatch:IDepartmentsPostPatchRequest = {
        name: dep.name,
        roleIds: dep.roles.map((r) => r.id),
        locationIds: dep.locations.map((l) => l.id),
        taskTemplateIds: dep.tasks.map((t) => t.id),
        workflowTemplateIds: dep.workflowTemplates.map((w) => w.id),
        supervisorIds: dep.supervisors.map((w: INameId) => w.id),
        processTemplateIds: dep.processTemplates.map((p) => p.id),
        taskCycleTemplateIds: dep.taskCycleTemplates.map((c)=>c.id)
      }
      const resp = await baseUrl.post('/departments', objForDispatch, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const deleteDepartment = createAsyncThunk(
  'departments/deleteDepartment',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('departments/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleDepartment = createAsyncThunk(
  'departments/getSingleDepartment',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<IPaginatedResponse<IDepratmnetsGetSingleResponse>> = await baseUrl.get('departments/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateDepartment = createAsyncThunk(
  'departments/updateDepartment',
  async (dep: IDepartmentsState, thunkAPI) => {
    try {
      const { id } = dep
      const objForDispatch:IDepartmentsPostPatchRequest = {
        name: dep.name,
        roleIds: dep.roles.map((r) => r.id),
        locationIds: dep.locations.map((l) => l.id),
        taskTemplateIds: dep.tasks.map((t) => t.id),
        workflowTemplateIds: dep.workflowTemplates.map((w) => w.id),
        supervisorIds: dep.supervisors.map((w) => w.id),
        processTemplateIds: dep.processTemplates.map((p) => p.id),
        taskCycleTemplateIds: dep.taskCycleTemplates.map((c)=>c.id)
      }
      delete dep.id
      const resp = await baseUrl.patch('departments/' + id, objForDispatch, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    clearDepartment: (state) => {
      state.singleDepartment = null
    },
    clearDepartmentsList: (state) => {
      state.departments = []
      state.totalCount = 0
      state.totalPages = 0
    },
  },
  extraReducers: {
    [getDepartments.pending.type]: (state) => {
      state.isLoading = true
    },
    [getDepartments.fulfilled.type]: (state:IDepartmentsSlice, { payload }:{payload:IPaginatedResponse<INameId>}) => {
      state.isLoading = false
      state.departments = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noDepartments = payload.data.length < 1
    },
    [getDepartments.rejected.type]: (state) => {
      state.isLoading = false
    },
    [addDepartment.pending.type]: (state) => {
      state.isLoading = true
    },
    [addDepartment.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      if (state.departments.length < 30) {
        state.departments = [...state.departments, payload]
      }
      state.noDepartments = false
      toast.success('Department added')
    },
    [addDepartment.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleDepartment.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleDepartment.fulfilled.type]: (state:IDepartmentsSlice, { payload }:{payload:IDepratmnetsGetSingleResponse}) => {
      state.isLoading = false
      const copy:any = {...payload}
      copy.supervisors = payload.supervisors.map(s=>({id:s.id, name:s.firstName + ' ' + s.lastName}))
      state.singleDepartment = copy
    },
    [getSingleDepartment.rejected.type]: (state) => {
      state.isLoading = false
    },
    [updateDepartment.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateDepartment.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const foundIndex = state.departments.findIndex((item) => item.id === payload.id)
      state.departments[foundIndex] = payload
      toast.success('Department updated')
    },
    [updateDepartment.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteDepartment.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.departments = state.departments.filter((item) => item.id !== payload)
      toast.success('Department deleted')
      if (state.departments.length < 1) state.noDepartments = true
    },
  },
})

export default departmentsSlice.reducer
export const { clearDepartment, clearDepartmentsList } = departmentsSlice.actions
