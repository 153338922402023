import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl } from '../../utils/axios'
import { toast } from 'react-toastify'
import { handleErrors, formatParams } from 'utils/helpers'
import { IInventoryCategoriesSlice } from 'types/inventory.interface'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IInventoryCategoryPutRequest, IInventoryCategoryResponse } from 'types/api/categoriesApi.interface'

const initialState:IInventoryCategoriesSlice = {
  isLoading: false,
  inventoryCategories: [],
  singleInventoryCategory: null,
  noInventoryCategories: false,
  totalCount: 0,
  totalPages: 0,
  noFields: false,
}

export const getInventoryCategories = createAsyncThunk(
  'inventoryCategories/getInventoryCategories',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<IInventoryCategoryResponse>> = await baseUrl.get('inventory-categories', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addInventoryCategory = createAsyncThunk(
  'inventoryCategories/addInventoryCategory',
  async (category: {name:string}, thunkAPI) => {
    try {
      const resp = await baseUrl.post('inventory-categories', category, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleInventoryCategory = createAsyncThunk(
  'inventoryCategories/getSingleInventoryCategory',
  async (id: number | string, thunkAPI) => {
    try {
      const resp:AxiosResponse<IInventoryCategoryResponse> = await baseUrl.get('inventory-categories/' + id, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateInventoryCategory = createAsyncThunk(
  'inventoryCategories/updateInventoryCategory',
  async (category: IInventoryCategoryPutRequest, thunkAPI) => {
    try {
      const { id } = category
      const resp = await baseUrl.put(
        'inventory-categories/' + id,
        {
          name: category.name,
          fields: category.fields || [],
        },
        thunkAPI,
      )

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteInventoryCategory = createAsyncThunk(
  'inventoryCategories/deleteInventoryCategory',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('inventory-categories/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const inventoryCategoriesSlice = createSlice({
  name: 'inventoryItems',
  initialState,
  reducers: {
    clearInventoryCategory: (state: any) => {
      state.singleInventoryCategory = {}
    },
    clearInventoryCategoriesList: (state: any) => {
      state.inventoryCategories = []
      state.totalCount = 0
      state.totalPages = 0
    },
  },
  extraReducers: {
    [addInventoryCategory.pending.type]: (state) => {
      state.isLoading = true
    },
    [addInventoryCategory.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.inventoryCategories = [...state.inventoryCategories, payload]

      state.noInventoryCategories = state.inventoryCategories.length < 1
      toast.success('Category added')
    },
    [addInventoryCategory.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getInventoryCategories.pending.type]: (state) => {
      state.isLoading = true
    },
    [getInventoryCategories.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.noInventoryCategories = payload.data.length > 0 ? false : true
      state.inventoryCategories = payload.data
      state.totalPages = payload.totalPages
      state.totalCount = payload.totalCount

    },
    [getInventoryCategories.rejected.type]: (state) => {
      state.isLoading = false
    },
    [getSingleInventoryCategory.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleInventoryCategory.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleInventoryCategory = payload
      state.noFields = payload.fields.length < 1
    },
    [getSingleInventoryCategory.rejected.type]: (state) => {
      state.isLoading = false
    },
    [updateInventoryCategory.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateInventoryCategory.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const foundIndex = state.inventoryCategories.findIndex((cat) => cat.id == payload.id)
      state.inventoryCategories[foundIndex] = payload
      state.noFields = payload.fields.length < 1
      toast.success('Category updated')
    },
    [updateInventoryCategory.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteInventoryCategory.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteInventoryCategory.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.inventoryCategories = state.inventoryCategories.filter(
        (category) => category.id !== payload,
      )
      toast.success('Category deleted')
    },
    [deleteInventoryCategory.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})
export const { clearInventoryCategory, clearInventoryCategoriesList } =
  inventoryCategoriesSlice.actions

export default inventoryCategoriesSlice.reducer
