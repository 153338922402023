import { ArrowLeft } from 'assets/icons/ArrowLeft'
import { ThreeDots } from 'assets/icons/ThreeDots'
import { DashboardCardHeadline, SquareIcon } from 'styling/GlobalStyles'

interface ITitle {
    mainIcon: JSX.Element,
    title: string,
    subtitle?: string
    color?: string
    rightSide?: JSX.Element
    height?: string
    center?: boolean
    onClick?: () => void
    arrow?: boolean
}

export const Title = ({ mainIcon, title, subtitle, color, rightSide, height, center, onClick, arrow }: ITitle) => {
    return <DashboardCardHeadline height={height} center={center}>
        <div>
            <SquareIcon color={color} >
                {mainIcon}
            </SquareIcon>
            <div>
                <p className='bold' >{title}</p>
                {subtitle && <p style={{ fontSize: '.7rem' }} >{subtitle}</p>}
            </div>
        </div>
        {rightSide ?? <span style={{ cursor: 'pointer' }} onClick={() => onClick && onClick()}>{arrow ? ArrowLeft : ThreeDots}</span>}
    </DashboardCardHeadline>
}