import { Routes, Route } from 'react-router-dom'
import { ScrollToTop } from './components/ScrollToTop'
import { Error, SharedLayout, Login } from './pages/index'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { clientRoutes } from './routes/clientRoutes'
import { adminRoutes } from './routes/adminRoutes'
import { SpinnerCondition } from './SpinnerCondition'
import useAuth from './hooks/useAuth'
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal'
import { useDispatch, useSelector } from 'react-redux'
import { setConfirmModal } from 'features/global/globalSlice'
import { AppDispatch } from 'store'
import { IRoute } from 'types/global.interface'

const Home = () => {
  const { isAdmin } = useAuth()
  const { confirmModal } = useSelector((store: any) => store.globals)
  const dispatch = useDispatch<AppDispatch>()

  const handleCancel = () => {
    dispatch(setConfirmModal({ isOpen: false }))
  }

  const renderRoutes = (routes) => {
    return routes.map((route: IRoute, index: number) => {
      return <Route path={route.path} element={<route.Component />} key={index} />
    })
  }

  return (
    <>
      <SpinnerCondition />
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<SharedLayout />}>
          {renderRoutes(isAdmin ? adminRoutes : clientRoutes)}
        </Route>
        <Route path='*' element={<Error />} />
      </Routes>
      <ToastContainer
        closeButton={false}
        pauseOnHover={false}
        closeOnClick={true}
        autoClose={1100}
        position='top-center'
      />
      {confirmModal.isOpen && (
        <ConfirmModal
          word={confirmModal.word}
          onSave={confirmModal.onSave}
          onCancel={handleCancel}
          setParams={confirmModal.setParams}
          sentence={confirmModal.sentence}
        />
      )}
    </>
  )
}

const App = () => {
  const auth = useAuth()

  return <>{auth.user ? <Home /> : <Login />}
    <ToastContainer
      closeButton={false}
      pauseOnHover={false}
      closeOnClick={true}
      autoClose={1100}
      position='top-center'
    /></>
}

export default App
