/* eslint-disable @typescript-eslint/no-empty-function */
import { employeesIcon } from 'assets/icons/EmployeesIcon'
import { CustomLabel } from 'components/Charts/Charts'
import { LineItem } from 'components/Dashboard/LineItem'
import { Title } from 'components/Dashboard/Title'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { RootState } from 'store'
import { ChartTooltip, DashboardCard, DCContent, P } from 'styling/GlobalStyles'
import { groputDashboardItems } from 'utils/helpers'
import { WidgetEditOverlay } from '../Dashboard.styled'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { IWidget } from 'types/dashboard.interface'

export const WorkersWidget = ({
    modalOpen,
    removeWidget,
    onDragStart,
    onDragOver,
    onDragDrop
}: IWidget) => {
    const { shifts } = useSelector((state: RootState) => state.dashboard)
    const shiftsData = groputDashboardItems(shifts?.shifts)
    const colors = ['#00a368', '#ff9900', '#ff5b5b', '#b46f33', '#30b6d1', '#7f0788', '#3c8f05333']

    const navigate = useNavigate()
    return (
        <DashboardCard
            draggable={modalOpen}
            onDragStartCapture={() => {
                onDragStart('workers', 1)
            }}
            onDrop={onDragDrop}
            onDragEnd={(e) => {
                e.preventDefault()
            }}
            onDragOver={(e) => {
                e.preventDefault()
                onDragOver('workers')
            }}
            width='1fr'
            style={{ gridArea: 'workers', cursor: modalOpen ? 'grab' : 'normal' }}
        >
            {modalOpen && (
                <WidgetEditOverlay
                    onClick={(e) => {
                        removeWidget('workers')
                        e.stopPropagation()
                    }}
                >
                    <P>Remove Widget</P>
                    <CloseIcon white />
                </WidgetEditOverlay>
            )}
            <Title
                mainIcon={employeesIcon}
                title='Employees'
                subtitle='All shifts'
                arrow
                onClick={() => !modalOpen && navigate('/dashboard/employees')}
            />
            <DCContent>
                <ResponsiveContainer width='100%' height='60%'>
                    <PieChart>
                        <Tooltip
                            wrapperStyle={{ outline: 'none' }}
                            itemStyle={{ outline: 'none' }}
                            content={(props) => {
                                return (
                                    <ChartTooltip>
                                        <p>{props.payload[0]?.name}</p>
                                        <div>
                                            <h3 style={{ color: props.payload[0]?.payload?.fill }}>
                                                {props.payload[0]?.value}
                                            </h3>
                                            I
                                            <p>
                                                {' '}
                                                {(
                                                    (Number(props.payload[0]?.value) / Number(shifts?.totalCount)) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </p>
                                        </div>
                                    </ChartTooltip>
                                )
                            }}
                        />
                        <Pie data={shiftsData} dataKey='value' innerRadius={30} outerRadius={50}>
                            {shiftsData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))}
                            <Label
                                position='center'
                                content={<CustomLabel value={String(shifts?.totalCount)} />}
                            />
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <LineItem
                    borderTop
                    title='Low Rated Workers'
                    count={shifts?.lowRatedCount}
                    noBottom
                    numberOnly
                />
            </DCContent>
        </DashboardCard>
    )
}
