/*eslint-disable*/
import { CycleIcon } from 'assets/icons/CycleIcon'
import { Title } from 'components/Dashboard/Title'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'store'
import { DashboardCard, DCContent, DCTaskCount, Flex, P } from 'styling/GlobalStyles'
import { groputDashboardItems } from 'utils/helpers'
import { WidgetEditOverlay } from '../Dashboard.styled'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { IWidget } from 'types/dashboard.interface'
import { useState } from 'react'

export const CyclesWidget = ({ modalOpen, removeWidget, onDragStart, onDragOver, onDragDrop }: IWidget) => {
    const navigate = useNavigate()
    const { taskCycleStatuses } = useSelector((state: RootState) => state.dashboard)
    const cyclesData = groputDashboardItems(taskCycleStatuses)


    const getTaskStatus = (name: string): number => {
        return Number(cyclesData?.find(td => td.name == name)?.value) || 0
    }


    return <DashboardCard
        draggable={modalOpen}
        onDragStartCapture={() => {
            onDragStart('cycles', 1)
        }}
        onDrop={onDragDrop}
        onDragEnd={(e) => {
            e.preventDefault()
        }}
        onDragOver={(e) => {
            e.preventDefault()
            onDragOver('cycles')
        }}
        width='1fr'
        style={{ gridArea: 'cycles', cursor: modalOpen ? 'grab' : 'normal' }}
    >
        {modalOpen && <WidgetEditOverlay onClick={e => {
            removeWidget('cycles')
            e.stopPropagation()
        }}>
            <P>Remove Widget</P>
            <CloseIcon white />
        </WidgetEditOverlay>}
        <Title mainIcon={CycleIcon} title='Cycles' subtitle='All statuses' arrow onClick={() => !modalOpen && navigate('/dashboard/cycles')} />
        <DCContent height='100%' >
            <Flex gap='1rem' width='100%' >
                <DCTaskCount flex>
                    <p>Not Started:</p>
                    <p className='bold' >{getTaskStatus('Not Started')}</p>
                </DCTaskCount>
                <DCTaskCount flex>
                    <p>Completed:</p>
                    <p className='bold'>{getTaskStatus('Completed')}</p>
                </DCTaskCount>
            </Flex>
            <Flex gap='1rem' width='100%' >
                <DCTaskCount flex>
                    <p>In Progress:</p>
                    <p className='bold'>{getTaskStatus('In Progress')}</p>
                </DCTaskCount>
                <DCTaskCount flex>
                    <p>Idle:</p>
                    <p className='bold'>{getTaskStatus('Idle')}</p>
                </DCTaskCount>
            </Flex>
            <Flex gap='1rem' width='100%' >
                <DCTaskCount flex>
                    <p>Paused:</p>
                    <p className='bold'>{getTaskStatus('Paused')}</p>
                </DCTaskCount>

                <DCTaskCount width='100%' flex>
                    <p>Stopped:</p>
                    <p className='bold'>{getTaskStatus('Stopped')}</p>
                </DCTaskCount>
            </Flex>

        </DCContent>
    </DashboardCard>
}