import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { baseUrl } from '../../utils/axios'
import { IField, IFieldsSlice } from 'types/fields.interface'
import { handleErrors, formatParams } from 'utils/helpers'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { INameId } from 'constants/globalTypes'
import { IFieldsPatchRequest, IFieldsPostRequest, IFieldsSingleResponse } from 'types/api/fieldsApi.interface'

const initialState: IFieldsSlice = {
  isLoading: false,
  fields: [],
  singleField: {
    id: null,
    name: null,
    dataType: null,
    unit: null,
  },
  isSingleLoading: false,
  noFields: false,
  totalCount: null,
  totalPages: null,
  noSubfields: false,
}

export const getFields = createAsyncThunk('fields/getFields', async (params: any, thunkAPI) => {
  try {
    params = formatParams(params)
    const resp:AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get('/fields', { params })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const addField = createAsyncThunk('fields/addField', async (field: IField, thunkAPI) => {
  const forSubmit:IFieldsPostRequest = {...field}
  if (field.dropDownItems.length < 1) {
    delete forSubmit.dropDownItems
  }
  try {
    const resp = await baseUrl.post('fields', forSubmit, thunkAPI)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const editField = createAsyncThunk('fields/editField', async (field: IField, thunkAPI) => {
  const forSubmit:IFieldsPatchRequest = {...field}
  if (field.dropDownItems.length < 1) {
    delete forSubmit.dropDownItems
  }
  try {
    const resp = await baseUrl.put('fields/' + field.id, forSubmit, thunkAPI)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getSingleField = createAsyncThunk(
  'fields/getSingleField',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<IFieldsSingleResponse> = await baseUrl.get('fields/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteField = createAsyncThunk('fields/deleteField', async (id: number, thunkAPI) => {
  try {
    await baseUrl.delete('fields/' + id)

    // thunkAPI.dispatch(getFields())
    return id
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

const fieldsSlice = createSlice({
  name: 'fields',
  initialState,
  reducers: {
    clearField: (state) => {
      state.singleField = {
        id: null,
        name: null,
        dataType: null,
        unit: null,
      }
    },
    clearFieldsList: (state) => {
      state.fields = []
      state.totalCount=null
      state.totalPages=null
    },
  },

  extraReducers: {
    [getFields.pending.type]: (state) => {
      state.isLoading = true
    },
    [getFields.fulfilled.type]: (state:IFieldsSlice, { payload }:{payload:IPaginatedResponse<INameId>}) => {
      state.isLoading = false
      state.fields = payload.data
      state.totalCount=payload.totalCount
      state.totalPages=payload.totalPages
      state.noFields = state.fields.length < 1
    },
    [getFields.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleField.pending.type]: (state) => {
      state.isSingleLoading = true
    },
    [getSingleField.fulfilled.type]: (state:IFieldsSlice, { payload }:{payload:IFieldsSingleResponse}) => {
      state.isSingleLoading = false
      state.singleField = {
        ...payload,
        dropDownItems: payload.dropDownItems.map(item => ({
          ...item,
          id: item.id.toString()
        }))
      }
      if (payload.dropDownItems) {
        state.noSubfields = payload.dropDownItems.length < 1
      }
    },
    [getSingleField.rejected.type]: (state) => {
      state.isSingleLoading = false
    },
    [addField.pending.type]: (state) => {
      state.isLoading = true
    },
    [addField.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.fields = [...state.fields, payload]
      state.noFields = false
      toast.success('Field added')
    },
    [addField.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [editField.pending.type]: (state) => {
      state.isLoading = true
    },
    [editField.fulfilled.type]: (state, { payload }) => {
      state.singleField = payload
      state.isLoading = false
      state.noSubfields = payload.dropDownItems.length < 1
      toast.success('Field edited')
    },
    [editField.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteField.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteField.fulfilled.type]: (state, { payload }) => {
      state.fields = state.fields.filter((item) => item.id !== payload)
      if (state.fields.length < 1) {
        state.noFields = true
      }
      state.isLoading = false
      toast.success('Field deleted')
    },
    [deleteField.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearField, clearFieldsList } = fieldsSlice.actions

export default fieldsSlice.reducer
