/* eslint-disable @typescript-eslint/no-empty-function */
import { inventoryIcon } from 'assets/icons/InventoryIcon'
import { LineItem } from 'components/Dashboard/LineItem'
import { Title } from 'components/Dashboard/Title'
import { useEffect, useState } from 'react'
import { DashboardCard, DCContent, P } from 'styling/GlobalStyles'
import { setState } from 'utils/helpers'
import { InventoryModal } from '../InventoryModal'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { WidgetEditOverlay } from '../Dashboard.styled'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { IWidget } from 'types/dashboard.interface'
import { createPortal } from 'react-dom'

export const InventoryWidget = ({
    modalOpen,
    removeWidget,
    onDragStart,
    onDragOver,
    onDragDrop,
}: IWidget) => {
    const [inventory, setInventory] = useState({ modalOpen: false, selectedItems: [] })
    const [selectedItems, setSelectedItems] = useState([])
    const { inventoryItems } = useSelector((state: RootState) => state.dashboard)

    useEffect(() => {
        if (inventoryItems?.length > 0) {
            setSelectedItems(inventoryItems.slice(0, 5))
        }
    }, [inventoryItems])

    return (
        <DashboardCard
            draggable={modalOpen}
            onDragStartCapture={() => {
                onDragStart('inventory', 1)
            }}
            onDrop={onDragDrop}
            onDragEnd={(e) => {
                e.preventDefault()
            }}
            onDragOver={(e) => {
                e.preventDefault()
                onDragOver('inventory')
            }}
            width='1fr'
            style={{ gridArea: 'inventory', cursor: modalOpen ? 'grab' : 'normal' }}
            onClick={() => !modalOpen && setState(setInventory, 'modalOpen', !inventory.modalOpen)}
        >
            {modalOpen && (
                <WidgetEditOverlay
                    onClick={(e) => {
                        e.stopPropagation()
                        setState(setInventory, 'modalOpen', !inventory.modalOpen)
                        removeWidget('inventory')
                    }}
                >
                    <P>Remove Widget</P>
                    <CloseIcon white />
                </WidgetEditOverlay>
            )}
            <Title
                mainIcon={inventoryIcon}
                title='Inventory'
                subtitle='Items'
                onClick={() => !modalOpen && setState(setInventory, 'modalOpen', true)}
            />
            <DCContent noGap={selectedItems.length < 5}>
                {selectedItems?.map((i, n) => (
                    <LineItem
                        key={n}
                        borderTop={n == 5}
                        title={i.name}
                        count={i.quantity}
                        warning={i.isLowStock}
                    />
                ))}
            </DCContent>
            {inventory.modalOpen &&
                createPortal(
                    <InventoryModal
                        selectedItems={selectedItems}
                        list={inventoryItems}
                        setSelectedItems={setSelectedItems}
                        modal={inventory}
                        setModal={setInventory}
                    />,
                    document.body,
                )}
        </DashboardCard>
    )
}
