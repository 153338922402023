import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import logo from '../assets/images/logo.png'
import { ArrowLeft } from '../assets/icons/ArrowLeft'


export const theme = {
  colorWhite: '#ffffff',
  colorOrange: '#ff9900',
  colorOrangeHover: '#ea8e03',
  colorGray: '#464255',
  colorLightGray: '#c3c0cc',
  colorGrayHover: '#696675',
  colorPink: '#FF5B5B',
  shadowMedium: '0px 0px 30px rgba(0, 0, 0, 0.1)',
  greenChart: '#00A368',
  colorGreen: '#00B04C',
  grayChart: '#F5F5F5',
  brownChart: '#B46F33',
  blueChart: '#30B6D1',
  colorRed: '#FF4F46',
  redChart: '#FF0000',
  borderInputActive: '1px solid #efefef',
  shadowInputActive: '0px 0px 30px rgba(0, 0, 0, 0.1)',
  borderTabInactive: '1px solid #464255',
  lightBlue: ' #00F0FF',
}


export const LogoStyled = styled.div`
  img {
    margin-top: 1.4rem;
    width: 7.75rem;
  }
`
export const Logo = () => {
  return (
    <LogoStyled>
      <img src={logo} alt='logo-img' />
    </LogoStyled>
  )
}

interface ProfilePictureProps {
  noRadius?: boolean
  m?: string
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  show?: boolean
}

export const ProfilePicture = styled.div<ProfilePictureProps>`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  transition: all 0.7s ease-in-out;

  overflow: hidden;
  cursor: pointer;
  border-radius: ${(props) => (props.noRadius ? '' : '50%')};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
`
interface DashboardContainerProps {
  orange: string
  theme: {
    colorOrange: string
    colorGray: string
  }
}
export const DashboardContainer = styled.div<DashboardContainerProps>`
  position: fixed;
  top: calc(4.375rem + 1.5rem);
  left: calc(15.625rem + 1.5rem);
  bottom: 0;
  right: 0;
  overflow-y: auto;
  padding: 0 1.5rem;
  padding-top: 1.5rem;

  /* color: ${(props) => (props.orange ? props.theme.colorOrange : props.theme.colorGray)}; */
`


export const LinkCustom = styled(Link)`
  font-size: 0.7rem;
  color: ${(props) => props.theme.colorGray}90;
  text-decoration: none;
`

export const BreadCrumbsWrapper = styled.div`
  display: flex;
  gap: 0.3rem;
  margin-left: 2.5rem;
  button,
  p {
    background-color: transparent;
    border: none;

    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.1rem;
    /* identical to box height */

    /* Grey */

    color: ${(props) => props.theme.colorGray}90;
  }
  button:hover {
    color: ${(props) => props.theme.colorOrange};
  }
`
interface TitleProps {
  caps?: string
  pad?: string
  orange?: boolean
  fontSize?: string
}

export const Title = styled.div<TitleProps>`
  font-weight: 700;
  line-height: 3rem;
  user-select: none;
  ${(props) => props.caps && 'text-transform: uppercase'}
  ${(props) => props.pad && 'padding:' + props.pad};
  color: ${(props) => props.theme.colorGray};
  color: ${(props) => (props.orange ? props.theme.colorOrange : props.theme.colorGray)};
`

interface FlexProps {
  between?: boolean
  main?: boolean
  justcenter?: boolean
  alignEnd?: boolean
  alignStart?: boolean
  padding?: string
  end?: string
  start?: string
  center?: boolean
  column?: boolean
  gap?: string
  relative?: boolean
  m?: string
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  width?: string
  wrap?: boolean
  scroll?: boolean
  position?: string
  pointer?: string
  maxHeight?: string
  minHeight?: string
  top?: boolean
  height?: string
  overflow?: boolean
  mediaQ?: boolean
  overflowAuto?: boolean
  maxWidth?: string
  minWidth?: string
  paddingLeft?: string
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${(props) => props.between && 'justify-content: space-between'};
  ${(props) => props.justcenter && 'justify-content: center'};
  ${(props) => props.end && 'justify-content: end'};
  ${(props) => props.alignEnd && 'align-items: flex-end'};
  ${(props) => props.alignStart && 'align-items: flex-start'};
  ${(props) => props.start && 'justify-content: start'};
  ${(props) => props.center && 'align-items:  center'};
  ${(props) => props.top && 'align-items:  flex-start'};
  ${(props) => props.column && 'flex-direction: column'};
  ${(props) => props.gap && `gap:${props.gap}`};
  ${(props) => props.relative && 'position:relative'};
  ${(props) => (props.m ? 'margin:' + props.m : '')};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
  width: ${(props) => props.width};
  ${(props) => props.position && 'position:' + props.position};
  ${(props) => props.pointer && 'cursor:pointer'};
  ${(props) => props.maxHeight && 'max-height:' + props.maxHeight};
  ${(props) => props.maxWidth && 'max-width:' + props.maxWidth};
  ${(props) => props.minWidth && 'min-width:' + props.minWidth};
  ${(props) => props.minHeight && 'min-height:' + props.minHeight};
  ${(props) => props.height && 'height:' + props.height};
  ${(props) => props.overflow && 'overflow-x:hidden'};
  ${(props) => props.overflowAuto && 'overflow:auto'};
  ${(props) => props.padding && 'padding:' + props.padding};
  ${(props) => props.paddingLeft && 'padding-left:' + props.paddingLeft};

  h1 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 100%;
    color: ${(props) => props.theme.colorGray};
  }

  ${(props) => props.main && '@media(max-width: 1000px){flex-direction:column; gap:1rem}'}
  @media (max-width: 1200px) {
    flex-direction: ${(props) => (props.mediaQ ? 'column' : '')};
    align-items: ${(props) => (props.mediaQ ? 'center' : '')};
  }
`
interface ButtonProps {
  m?: string
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  width?: string
  height?: string
  pink?: boolean
  orange?: boolean
  disabled?: boolean
  white?: boolean
}

export const Button = styled.button<ButtonProps>`
  border: none;
  width: 11.25rem;
  pointer-events: all !important;
  height: ${(props) => (props.height ? props.height + 'px' : '2.5rem')};
  width: ${(props) => (props.width ? props.width + 'px' : '11.25rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.white ? props.theme.colorGray : '#fff'};
  text-transform: capitalize;
  user-select: none;
  ${(props) =>
    props.pink
      ? `background:${props.theme.colorPink}`
      : props.orange
        ? `background:${'#ff9900'}`
        : props.white ? 'background:white'
          : `background:${props.theme.colorGray}`};
  border-radius: 10px;
  ${props => props.white && 'border: 1px solid ' + props.theme.colorGray};
  padding: 0 1rem;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.3rem;
  cursor: pointer;
  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
  outline: none;
  ${(props) => props.disabled && 'opacity:0.6;'}
  transition:.2s ease;

  &:hover {
    filter: brightness(115%);
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(3px);
  }
`
interface BtnContainerProps {
  m: string
  mt: string
  mb: string
  ml: string
  mr: string
  noMin: boolean
}

export const BtnContainer = styled.div<BtnContainerProps>`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
  min-height: ${(props) => (props.noMin ? '' : '200px')};
  align-items: flex-end;
`

export const OrangeCircle = styled.div`
  width: 8.5rem;
  height: 8.5rem;
  background: ${(props) => props.theme.colorOrange};
  border-radius: 50%;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
`
interface GlobalFontsProps {
  color?: string
  pointer?: boolean
  center?: boolean
  flex?: boolean
  inline?: boolean
  w?: string
  m?: string
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  maxWidth?: string
  mSM?: string
  mtSM?: string
  mbSM?: string
  mlSM?: string
  mrSM?: string
  maxWidthSM?: string
}

export const GlobalFonts = styled.div<GlobalFontsProps>`
  font-weight: 900;
  font-size: 4.5rem;
  color: ${(props) => props.theme.colors};
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.pointer && `cursor: ${props.pointer};`}
  ${(props) => props.center && 'text-align: center;'}
  ${(props) => props.flex && 'display: flex;'}
  ${(props) => props.inline && 'display: inline;'}

  width: ${(props) => props.w};

  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}rem;
  margin-bottom: ${(props) => props.mb}rem;
  margin-left: ${(props) => props.ml}rem;
  margin-right: ${(props) => props.mr}rem;

  max-width: ${(props) => props.maxWidth};

  @media (max-width: 800px) {
    margin: ${(props) => props.mSM};
    margin-top: ${(props) => props.mtSM}rem;
    margin-bottom: ${(props) => props.mbSM}rem;
    margin-left: ${(props) => props.mlSM}rem;
    margin-right: ${(props) => props.mrSM}rem;
    max-width: ${(props) => props.maxWidthSM};
  }
`
interface HRProps {
  mt?: string
}
export const HR = styled.hr<HRProps>`
  opacity: 0.3;
  border: 0.4px solid #46425530;
  margin-top: ${(props) => props.mt}rem;
  width: 100%;
`

export const BigPageTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  text-transform: capitalize;
  color: ${(props) => props.theme.colorOrange};
  position: relative;
  /* hr {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0.3;
    height: 0.1px;
  } */
`

// TYPOGRAPHY

export const MainHeadline = styled(GlobalFonts)`
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colorOrange};
  text-transform: capitalize;

  @media (max-width: 500px) {
    font-size: 1.3rem;
  }

`

export const H1 = styled(GlobalFonts)`
  display: flex;
  align-items: center;
  gap: 1rem;
  button {
    margin-top: 0.3125rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  font-weight: 700;
  font-size: 1.5rem;
  color: ${(props) => props.theme.colorGray};
  line-height: 2.25rem;
  text-transform: capitalize;
  ${(props) => props.mb && 'margin-bottom:' + props.mb}

  @media (max-width: 1000px) {
    margin-bottom: 0;
  }

  @media (max-width: 500px) {
    font-size: 1rem;
  }

`

export const H1WithBack = ({ arrow, title, mb, link }: { arrow?: boolean, title: string, mb?: string, link?: string }) => {
  const navigate = useNavigate()
  return (
    <H1 mb={mb}>
      {arrow && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //  @ts-ignore
        <button type='button' onClick={() => navigate(link ? link : -1)}>
          {ArrowLeft}
        </button>
      )}
      <span>{title}</span>
    </H1>
  )
}


interface H2Props {
  noUpperCase?: boolean
  center?: boolean
  mb?: string
  ml?: string
}
export const H2 = styled(GlobalFonts) <H2Props>`
  font-weight: 700;
  font-size: 1rem;
  text-transform: ${(props) => (props.noUpperCase ? 'none' : 'uppercase')};
  line-height: 1.3rem;
  color: ${(props) => props.theme.colorGray};
  ${(props) => props.center && 'text-align:center;'}
  ${(props) => props.mb && 'margin-bottom:' + props.mb}
  ${(props) => props.ml && 'margin-left:' + props.ml}
`

interface PProps {
  bold?: boolean
  center?: boolean
  w100?: boolean
}

export const P = styled.p<PProps>`
  color: ${(props) => props.theme.colorGray};
  ${(props) => props.bold && 'font-weight:bold;'};
  ${(props) => props.center && 'text-align:center;'};
  ${(props) => props.w100 && 'width:100%;'};
`


export const H4Wrapper = styled.h4`
  color: ${(props) => props.color};
`

interface P1Props {
  red?: boolean
}

export const P1 = styled(GlobalFonts) <P1Props>`
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.3rem;
  color: ${(props) => props.theme.colorGray};
  background-color: ${(props) => (props.red ? 'red' : 'transparent')};
  color: ${(props) => (props.red ? 'white' : 'black')};
  padding: ${(props) => (props.red ? '0.2rem' : 'none')};
  border-radius: ${(props) => (props.red ? '5px' : 'none')};
  opacity: ${(props) => (props.red ? '0.9' : 'none')};
`
export const P2 = styled(GlobalFonts)`
  font-weight: 400;
  font-size: 0.6rem;
  line-height: 0.9rem;
  color: ${(props) => props.theme.colorGray};
`
interface ItemsGridProps {
  width?: string
  columns: number
  colgap?: string
  maxHeight?: string
  gap?: string
  mt?: string
  ml?: string
  mb?: string
  repeat?: boolean
  overflowNone?: boolean
}

export const SpecificationWrapper = styled.div`
  height: calc(100% - 3rem);
  overflow: auto;
`

export const ItemsGrid = styled.div<ItemsGridProps>`
  ${(props) => (props.width ? 'width:' + props.width : 'width:100%')};
  display: grid;
  grid-template-columns: ${(props) => 'repeat(' + props.columns + ',1fr)'};
  column-gap: ${(props) => (props.colgap ? props.colgap : '5em')};
  gap: ${(props) => (props.gap ? props.gap : '1rem')};
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  margin-left: ${(props) => (props.ml ? props.ml : 0)};
  ${(props) => props.repeat && 'grid-template-columns: repeat(auto-fit, minmax(80px, 70px));'}
  ${(props) => props.maxHeight && 'max-height:' + props.maxHeight}
  position: relative;

  @media (max-width: 1100px) {
   display: flex;
   flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100% !important;
  }

`

export const ButtonsContainer = styled.div`

display: flex;
gap:1rem;
@media (max-width: 500px) {
 height: max-content;
 justify-content: space-between;
 min-width: 90vw;
 button{
   width: 45%;
 }
  }

`

interface GridItemProps {
  valid?: boolean
  onClick?: () => any
  modalItem?: boolean
  noRightPadding?: boolean
}
export const GridItem = styled.div<GridItemProps>`
  width: 100%;
  display: flex;
  height: 2.5rem;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  align-items: center;
  background: #ffffff;
  box-shadow: 8px 1.1rem 2.8rem rgba(7, 6, 61, 0.05);
  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  ${(props) => props.modalItem && props.valid && 'border: 1px solid #ff9900;'}
  ${(props) => props.noRightPadding && 'padding-right:0;'}
  color: ${(props) => (props.valid ? props.theme.colorOrange : 'black')};
  transition: all 0.4s ease;

  p {
    width: 100%;
    min-height: 2.5rem;
    display: flex;
    padding: 0.3rem;
    display: flex;
    align-items: center;
  }

  &:hover {
    color: ${theme.colorOrange};
    transform: scale(1.03);
    transition: all 0.4s ease;
  }
`

export const GridWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
interface TextDisplayProps {
  width?: string
  color?: string
  minWidth?: string
  smallHeight?: boolean
  border?: boolean
}
export const TextDisplay = styled.div<TextDisplayProps>`
  min-height: ${({ smallHeight }) => (smallHeight ? '1rem' : '2.5rem')};
  height: ${({ smallHeight }) => smallHeight && '2.5rem'};
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.1rem;
  color: ${(props) => props.theme.colorGray};
  width: ${(props) => (props.width ? props.width : 'auto')};
  ${(props) => props.border && 'border:1px solid ' + props.theme.colorLightGray};
  background: #ffffff;
  box-shadow: 8px 1.25rem 45px rgba(7, 6, 61, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0.6rem 0.8rem;
  ${(props) => props.minWidth && 'min-width:' + props.minWidth}
`

interface SmallTextProps {
  bold?: boolean
}

export const SmallText = styled.div<SmallTextProps>`
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.3rem;
  font-weight: ${(props) => (props.bold ? '700' : '300')};
  color: ${(props) => props.theme.colorGray};
`
interface BlackCircleProps {
  orange?: boolean
  absolute?: boolean
  left?: string
  right?: string
  top?: string
}
export const BlackCircle = styled.div<BlackCircleProps>`
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${(props) => (props.orange ? props.theme.colorOrange : props.theme.colorGray)};
  border-radius: 50%;
  display: flex;
  margin-left: 0.9rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${(props) => props.absolute && 'position:absolute'};
  ${(props) => props.left && 'left:' + props.left};
  ${(props) => props.right && 'right:' + props.right};
  ${(props) => props.top && 'top:' + props.top};
  transition: 0.2s ease;

  &:hover {
    background-color: ${(props) => props.theme.colorGrayHover};
  }
  min-width: 2.5rem;
  min-height: 2.5rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
`

interface AddButtonProps {
  width?: string
  height?: string
  orange?: boolean
  m?: string
}

export const AddButton = styled.div<AddButtonProps>`
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : '2.5rem')};
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1.1rem;
  color: ${(props) => (!props.orange ? props.theme.colorGray : 'white')};
  background: ${(props) => (!props.orange ? '#f5f5f5' : props.theme.colorOrange)};
  border: 0.8px solid #e4e4e4;
  border-radius: 8.3px;
  cursor: pointer;
  svg {
    margin-right: 0.6rem;
  }
`
interface EditContainerProps {
  show?: boolean
  noBackground?: boolean
  invisible?: boolean
}
export const EditContainer = styled.div<EditContainerProps>`
  outline: none;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5.8px;
  cursor: pointer;
  transition: 0.2s;
  background-color: ${(props) => props.show && props.theme.colorOrange};
  ${(props) => props.noBackground && 'background:none;'}
  ${(props) => props.invisible && 'opacity:0; pointer-events:none;'}

  position: relative;
  > span {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: ${(props) => (props.show ? props.theme.colorWhite : props.theme.colorGray)};
    }
  }

  .arrow-table{
    svg{
      stroke: ${(props) => props.theme.colorGray};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.colorOrange};
    > span {
      svg {
        fill: #fff;
      }
    }

    .arrow-table{
    svg{
      stroke: #fff
    }
  }
  }
`

interface SeparatorProps {
  margin?: string
}
export const Separator = styled.div<SeparatorProps>`
  margin: ${(props) => (props.margin ? props.margin : '1.25rem 0')};
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colorGray}30;
  flex: none;
`

export const SeparatorVertical = styled.div<SeparatorProps>`
  margin: ${(props) => (props.margin ? props.margin : '0 1.25rem')};
  border-left: 1px solid ${(props) => props.theme.colorGray}30;
  flex: none;
  height: 80%;
`
interface CursorPointerProps {
  absolute?: string
  left?: string
  right?: string
  top?: string
  mt?: string
  ml?: string
  invisible?: boolean
  start?: string
  width?: string
}
export const CursorPointer = styled.div<CursorPointerProps>`
  cursor: pointer;
  ${(props) => props.absolute && 'position:absolute'};
  ${(props) => props.left && 'left:' + props.left};
  ${(props) => props.right && 'left:' + props.right};
  ${(props) => props.top && 'top:' + props.top};
  ${(props) => props.mt && 'margin-top:' + props.mt};
  ${(props) => props.ml && 'margin-left:' + props.ml};
  ${(props) => props.invisible && 'opacity:0;pointer-events:none;'};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.start ? 'start' : 'center')};

  svg {
    pointer-events: none;
  }
`

export const BottomRight = styled.div`
  z-index: 3;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
`

export const TopRight = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  padding: 0.3rem;
  svg {
    fill: white;
  }
`

export const Duplicate = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: ${(props) => props.theme.colorOrange};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  padding: 0.3rem;
  svg {
    fill: white;
  }
`

export const PaginationText = styled.div`
  font-weight: 300;
  font-size: 0.6rem;
  line-height: 1rem;
  color: ${(props) => props.theme.navy};
`

export const ArrowContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const ItemsPickContainer = styled.div`
  

  display: flex;
  gap: 2rem;
  @media (max-width: 500px) {
    
    flex-direction:column-reverse;
    height: max-content;
  }

`

interface TabsContainer {
  mediaQShifts?: boolean
}
export const TabsContainer = styled.div<TabsContainer>`
  margin: 0 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
  /* ::-webkit-scrollbar {
    display: none;
  } */
  z-index: 999999;
  margin-right: 1rem;
  margin-top: 1.5rem;
  max-height: 2.8rem;

  @media (max-width: 1370px) {
    max-height: ${(props) => props.mediaQShifts && 'none'};

    display: ${(props) => (props.mediaQShifts ? 'grid' : 'flex')};
    gap: ${(props) => (props.mediaQShifts ? '0.5rem' : '0.3rem')};
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  @media (max-width: 500px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom:1rem;
    max-height: none;
    display: flex;
    flex-direction: column;
    gap: ${(props) => (props.mediaQShifts ? '0.5rem' : '0.3rem')};
    margin-top: 0;


  }
  /* border: 2px solid black; */
`

export const SingleTab = styled.div`
  
  @media (max-width: 500px) {
  
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

`

interface TabsProps {
  active?: boolean
  mediaQShifts?: boolean
}

export const Tabs = styled.button<TabsProps>`
  margin-top: 5px;
  background: ${(props) => props.theme.colorWhite};
  min-width: 7.1rem;
  cursor: pointer;
  font-size: 0.8rem;
  position: relative;
  border: 0.8px solid #efefef;
  box-sizing: border-box;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: ${(props) => (props.active ? props.theme.colorOrange : props.theme.colorGray)};
  font-weight: ${(props) => (props.active ? 'bold' : '400')};
  height: 2.5rem;
  border: ${(props) => (props.active ? '1px solid #ff9900' : 'none')};
  border-bottom: none;
  transition: all 0.2s;
  opacity: ${(props) => (props.active ? '1' : '0.5')};
  z-index: 9999999999;
  filter: ${(props) => (props.active ? '' : ' drop-shadow(0px -4px 20px rgba(0, 0, 0, 0.10))')};

  ::after {
    display: ${(props) => (props.active ? 'block' : 'none')};
    width: 100%;
    content: '';
    height: 4px;
    background-color: white;
    position: absolute;
    bottom: -3px;
    z-index: 9999999;
    left: 0;

    @media screen and (max-width: 1370px) {
      ${(props) => props.mediaQShifts && 'display: none; '}
    }
  }

  @media screen and (max-width: 600px) {
    margin: 0 -1.25rem;
    padding: 0 1.25rem 2.5rem 1.25rem;
  }


  @media (max-width: 500px) {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius:0.5rem;
    border:1px solid #c3c0cc;
    ${(props) => props.active && 'border:1px solid #ff9900;'}
    overflow: hidden;
  }
`
interface CategoryCardProps {
  width?: string
}
export const CategoryCard = styled.div<CategoryCardProps>`
  z-index: 1;
  height: 8.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0.6rem 1.5rem 3.375rem rgba(7, 6, 61, 0.05);
  border-radius: 10px;
  width: ${(props) => (props.width ? props.width : '100%')};
  position: relative;
  padding: 0.3rem;
  color: ${(props) => props.theme.colorGray};
  cursor: pointer;

  img {
    border-radius: 0.5rem;
    max-height: 90%;
  }
`
export const CollapseWrapper = styled.div`
  @media (max-width: 920px) {
    display: flex;

    flex-direction: column;
    gap: 1rem;
  }
`
interface CollapsableProps {
  expanded: boolean
  view?: boolean
}
export const Collapsable = styled.div<CollapsableProps>`
  height: ${(props) => (props.expanded ? 'max-content' : '3.75rem')};
  height: ${(props) => (props.view && 'user-select:none; pointer-events:none')};
  overflow: hidden;
  width: 100%;
  padding: 0.6rem 1.25rem;
  transition: all 0.2s ease-out;
`
interface ImageContainerProps {
  width: string
  height: string
}

export const ImageContainer = styled.div<ImageContainerProps>`
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  display: flex;
  justify-content: center;
  padding: 0.6rem;
  object-fit: contain;
  align-items: center;
  border-radius: 0.5rem;

  img {
    width: 100%;
  }
`
export const CardsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.6rem;
  gap: 1rem;
  h3 {
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.3rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.colorGray};
  }
  span {
    font-weight: 700;
    font-size: 4rem;
    line-height: 100%;
    text-align: center;
    margin-top: 2rem;
    color: ${(props) => props.theme.colorGray};
  }
  div {
    padding: 1rem;
    height: 11.1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    color: ${(props) => props.theme.colorGray};
    background: #ffffff;
    box-shadow: 0.6rem 1.5rem 3.3rem rgba(7, 6, 61, 0.05);
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
  }
  div:hover {
    transform: scale(1.05);
  }
  article {
    background: #f5f5f5;
    border-radius: 10px;
    height: 2.8rem;
    width: 2.8rem;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  @media (max-width: 1300px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`
interface DividerProps {
  m?: string
  mt?: string
  mb?: string
  ml?: string
  mr?: string
}
export const Divider = styled.div<DividerProps>`
  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}rem;
  margin-bottom: ${(props) => props.mb}rem;
  margin-left: ${(props) => props.ml}rem;
  margin-right: ${(props) => props.mr}rem;
`

interface WhiteBackgroundProps {
  width?: string
  height?: string
  mb?: string
  animate?: boolean
}

export const WhiteBackground = styled.div<WhiteBackgroundProps>`
  background: #ffffff;
  box-shadow: 0.5rem 1.2rem 2.8rem rgba(7, 6, 61, 0.05);
  border-radius: 8.3px;
  padding: 1.3rem 1rem;
  ${(props) => props.width && 'width:' + props.width};
  ${(props) => props.height && 'height:' + props.height};
  ${(props) => props.mb && 'margin-bottom:' + props.mb};
  position: relative;
  ${(props) => props.animate && 'animation: popup .2s ease-in;'}
  @keyframes popup {
    from {
      transform: translateY(-1.25rem);
      opacity: 0;
    }
    to {
      transform: none;
      opacity: 1;
    }
  }
`
interface AbsoluteElementProps {
  top?: string
  left?: string
  right?: string
  bottom?: string
  width?: string
  height?: string
  events?: boolean
}

export const AbsoluteElement = styled.div<AbsoluteElementProps>`
  position: absolute;
  top: ${(props) => (props.top ? props.top : '')};
  left: ${(props) => (props.left ? props.left : '')};
  right: ${(props) => (props.right ? props.right : '')};
  bottom: ${(props) => (props.bottom ? props.bottom : '')};
  width: ${(props) => (props.width ? props.width : '')};
  height: ${(props) => (props.height ? 'height: ' + props.height : '')};
  ${(props) => props.events && 'pointer-events:none;'}
`

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

interface ITable {
  width?: string
  modal?: boolean
  minHeight?: string
  height?: string

}

export const Table = styled.div<ITable>`
  ${props => props.width && 'width: ' + props.width + ';'}
  ${props => props.minHeight && 'min-height: ' + props.minHeight + ';'}
  ${props => props.height && 'height: ' + props.height + ';'}
  ${props => props.modal && `max-height: 100%;
  overflow: auto;
  height:100%;
  padding: 0 1.5rem;
  padding-bottom:5rem;
  border-top: 1px solid ${props.theme.colorGray}30;
  margin-top:1rem !important;

  `

  }
  
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media screen and (max-width: 1100px) {
    margin: 0 -3.1rem;
    padding: 0 3.1rem 2.5rem 3.1rem;
    overflow-x: auto;
  }
  @media screen and (max-width: 1000px) {
    margin: 0 -1.8rem;
    padding: 0 1.8rem 2.5rem 1.8rem;
  }
  @media screen and (max-width: 600px) {
    margin: 0 -1.25rem;
    padding: 0 1.25rem 2.5rem 1.25rem;
  }
`
interface ThProps {
  mb?: string
  minWidth?: string | number;
  next?: boolean
}
export const Th = styled.div<ThProps>`
  /* border-top: 0.5px solid ${(props) => props.theme.colorGray}30; */
  width: 100%;
  height: 3.1rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colorGray};
  margin-bottom: 0;
  min-width: ${props => props.minWidth ?? '62.5rem'};
  padding: 0.3rem 0.6rem;
  ${props => !props.next && 'justify-content: space-between;'}
  // no tooltip for Table Heading
  div {
    overflow: visible;
    &:hover:after {
      background: none;
      content: '';
    }
  }
`

interface ITr {
  minWidth?: string | number
  selected?: boolean
  small?: boolean
  pointer?: boolean
  overflowHidden?: boolean
  hasSubs?: boolean
  open?: boolean
  sub?: boolean
}

export const Tr = styled.div<ITr>`
  background: #ffffff;
  box-shadow: ${(props) => props.theme.shadowMedium};
  ${props => props.selected && 'border: 1px solid #ff9900;'}
  border-radius: 10px;
  width: 100%;
  height: ${props => props.sub ? '3.5rem' : props.open ? 'max-content' : props.small ? '3rem' : '4.25rem'};
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.1rem;
  color: #5a5a5a;
  margin-bottom: 0.6rem;
  padding: 0.3rem 0.6rem;
  transition: all 0.2s;
  position: relative;
  min-width: ${props => props.minWidth ?? '62.5rem'};
  justify-content: space-between;
  ${props => props.sub && 'border:1px solid #D6D6D6'};
  ${props => props.sub && '*{font-size:11px}'};
  ${props => props.sub && 'padding:0 1rem'};
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.3rem;
  transition: .2s ease-out ;
  color: ${(props) => props.theme.colorGray};
  ${(props) => props.pointer && 'cursor:pointer;'};
  ${(props) => props.overflowHidden && '  overflow: hidden;'};
  ${(props) => props.hasSubs && ' flex-direction:column; gap:.2rem'};
  ${(props) => props.open && 'border:1px solid #ff9900'};
  /* &:nth-last-child(1) {
    overflow: visible;
    &:hover::after {
      content: '';
    }
  } */

  /* :hover {
    opacity: 0.8;
  } */
`

export const TrChevronContainer = styled.div<{ open?: boolean }>`

  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s cubic-bezier(0.075, 0.82, 0.165, 1);

  ${(props) => props.open && 'transform:rotate(180deg);'}

`


export const TrContentContainer = styled.div`

  width: 100%;
  display: flex;
  align-items: center;
  min-height: 3.85rem;
`

export const SubsContainer = styled.div`

  width:100%;
  height: max-content;
  margin-top: 1.5rem;
`

export const SubTr = styled(Tr)`

  height: 4rem;

`

interface PageWrapperProps {
  noFooter?: boolean
}

export const PageWrapper = styled.div<PageWrapperProps>`
  position: relative;
  padding: 1rem;
  width: 100%;
  height: ${(props) => (props.noFooter ? 'calc(100vh - 5rem)' : 'calc(100vh - 10rem)')};
  /* max-height: calc(100vh - 12.5rem); */
  overflow-y: auto;
  overflow-x: visible;
  ::-webkit-scrollbar {
    height: 0px;
    width: 10px;
  }
  @media (max-width: 500px) {
  padding: 1rem 0;
  height: ${(props) => (props.noFooter ? 'calc(100vh - 5rem)' : 'calc(100vh - 10rem)')};

  }

  /* margin-bottom: 1rem; */
`
interface IContentWrapper {
  smaller?: boolean
  noFooter?: boolean
  noPadding?: boolean
  fitContent?: boolean
  map?: boolean
}


const popup = keyframes`
  from {
    opacity: 0;
    scale:0.92; // Optional: Adds a slight zoom effect
  }
  to {
    opacity: 1;
    scale:1; 
  }
`;

export const ContentWrapper = styled.div<IContentWrapper>`
  background: #ffffff;
  position: relative;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 16.6667px;
  padding: ${(props) => (props.noPadding ? '0' : '1rem')};
  padding-bottom: 0;
  border-top: 1px solid #ff9900;
  overflow: auto;
  ${(props) => (props.smaller ? 'height: calc(100vh - 22rem);' : 'height: calc(100vh - 11.3rem);')}
  ${(props) => (props.fitContent && 'height:fit-content !important;')}
  @media (max-width: 500px) {
    padding: 1rem 5px;
    height:auto;
    ${(props) => (props.map && 'min-height:25rem !important;')}

  }


`

export const InvalidText = styled.p`
  color: crimson;
  font-size: 0.7rem;
  margin-left: 0.4rem;
`

interface IImageUploadProps {
  width?: string
  height?: string
}

export const ImageUpload = styled.div<IImageUploadProps>`
  display: flex;
  ${(props) => props.width && 'width: ' + props.width};
  ${(props) => props.height && 'height: ' + props.height};
  align-items: center;
  justify-content: center;
  position: relative;

  button {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
  }

  input {
    z-index: -1;
    position: absolute;
    left: 0;
    opacity: 0;
  }
`

export const SelectImageContainer = styled.div`
  max-width: 100%;
  margin-right: 1rem;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  img {
    height: 100%;
    max-width: 100%;
  }
`

export const ScrollContainer = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
`



export const Animation = styled.div`
  min-height: 100%;
  height:fit-content;
  animation: ${popup} 0.2s ease forwards; // Ensure 'forwards' so the animation persists
`

export const AnimationModal = styled.div`
  > div:nth-of-type(1) {
    animation-name: popup;
    animation-duration: 0.3s;
  }

  @keyframes popup {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

export const PageFooter = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2rem;

  @media (max-width: 1300px) {
   padding: 0;
  }

`

interface ITabFooter {
  pagination?: boolean
  contract?: boolean
  start?: boolean
}

export const TabFooter = styled.div<ITabFooter>`
  width: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  display: flex;
  pointer-events: none;
  justify-content: ${(props) =>
    props.pagination ? 'space-between' : props.start ? 'flex-start' : 'flex-end'};
  bottom: ${({ contract }) => !contract && 0};
  align-items: center;
  padding: 1rem;
  padding-right: 0;
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 1rem;
    position: relative;
  }
`

interface DropdownProps {
  show?: boolean
  left?: boolean
  color?: string
  align?: boolean
}

export const Dropdown = styled.div<DropdownProps>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  user-select: none;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 90000000009;
  position: absolute;
  text-align: ${(props) => props.align && 'center'};
  max-height: 12.5rem;
  overflow-y: auto;
  width: 100%;
  transition: ${(props) => props.show && '0.5s'};
  ${(props) => (props.left ? 'right:110%;top:0;width:10rem' : 'left:0;top:3.4rem;width:100%')};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  > div {
    cursor: pointer;
    font-size: 0.75rem;
    padding: 0.2rem 0.5rem;
    ${(props) => props.left && 'padding:0.6rem'};
  }
  > div:hover {
    color: ${(prop) => prop.theme.colorOrange};
    svg {
      fill: ${(prop) => prop.theme.colorOrange} !important;
    }
  }
  svg {
    fill: ${(prop) => prop.theme.colorGray} !important;
  }
  span {
    font-size: 1rem;
    background-color: ${(prop) => prop.theme.colorOrange};
    padding: 0rem 4rem 0 4rem;
    color: white;
    border-radius: 5px;
  }
`

export const Placeholder = styled.span`
  color: #777777;
`

export const IconInTheBackground = styled.div`
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: scale(6);
    opacity: 0.14;
    fill: ${(props) => props.theme.colorOrange};
  }
`
export const H4WhiteBackgournd = styled.h4`
  cursor: pointer;
  background-color: white;
  padding: 0.2rem;
  border-radius: 5px;
`
interface ISelectListContainerProps {
  width?: string
  view?: boolean
}

export const SelectListContainer = styled.div<ISelectListContainerProps>`
  width: ${(props) => (props.width ? props.width : '60%')};
  width: ${(props) => (props.view && 'user-select:none; pointer-events:none')};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  position: relative;
  overflow: auto;
  padding: 1rem;
  height: calc(100vh - 18rem);

  background: #ffffff;
  box-shadow: 0px 0px 25.0045px rgba(0, 0, 0, 0.1);
  border-radius: 12.5px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 500px) {
  
    width: 100%;

  }
`

interface SelectedItemsContainerProps {
  nopad?: boolean
  w?: string
}

export const SelectedItemsContainer = styled.div<SelectedItemsContainerProps>`
  min-width: 40%;
  max-height: calc(100vh - 18rem);
  min-height: calc(100vh - 18rem);
  width: auto;
  background: #ffffff;
  box-shadow: 0px 0px 25.0045px rgba(0, 0, 0, 0.1);
  border-radius: 12.5px;
  padding: 1rem;
  position: relative;
  overflow-y: hidden;
  width: ${(props) => (props.w ? props.w : 'auto')};
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  h5 {
    font-size: 0.7rem;
    margin-bottom: 0.3rem;
  }

  @media (max-width: 500px) {
  
    height: max-content;
    max-height: max-content;

  }
`

export const clickAnimataion = keyframes`
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: none;
  }
`

interface ICheckBox {
  active: boolean
}

export const CheckBox = styled.div<ICheckBox>`
  width: 15px;
  height: 15px;
  border-radius: 5px;
  ${(props) =>
    !props.active ? 'border:1px solid #ff9900; background-color:white' : 'background-color:#ff9900'}
`


interface IHTMLMapProps {
  globalView?: boolean
}

export const MapHTMLComponentsOverlay = styled.div<IHTMLMapProps>`
  position: absolute;
  top:0;
  left: 0;
  height: ${props => props.globalView ? '90%' : '100%'};
  width: 100%;
  border-radius: 15px;
`

export const ClientDashboardContainer = styled.div`
  max-width: 100%;
  width: 100%;
  height: calc(100% - 4rem);
  display: flex;
  gap:1rem;
  margin-top: 1rem;
  @media (max-width: 500px) {
    height: max-content;
  }
`

export const DashboardCard = styled.div<{ width?: string, height?: string }>`
overflow: hidden;
  min-width: 0;
  ${props => props.height && 'height:' + props.height};
  max-height: 100%;
  border-radius:10px;
  box-shadow: 8.33482551574707px 20.003582000732422px 45.008056640625px 0px #07063D0D;
  display: flex;
  background: white;
  flex-direction: column;
  gap:1rem;
  padding: 1rem;
  animation: placeBounce .5s forwards;
  transition: all .5s ease-in;

  @media (max-width: 500px) {
    min-width: 100% !important;
    position: relative;
    min-height: 15rem;
    height: 20rem;
  }

  @keyframes placeBounce {
  0% {
    opacity: 0;
    transform: scale(0.5) translateY(-50px); /* Start smaller and above */
  }
  50% {
    opacity: 1;
    transform: scale(1.1) translateY(0); /* Slightly overshoot size */
  }
  70% {
    transform: scale(0.95) translateY(0); /* Slightly compress */
  }
  100% {
    transform: scale(1) translateY(0); /* Settle into place */
  }
}

`

export const PageControls = styled.div`
  
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }

`

interface IDashboardCardHeadline {
  height?: string
  center?: boolean
}
export const DashboardCardHeadline = styled.div<IDashboardCardHeadline>`
  display:flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.height ?? '20%'};

  span{
    height: 100%;

    svg{
      font-size: 7rem !important;
      height: 100%;
      width: 1.7rem;
      stroke: #464255;
      transform: rotate(180deg);
    }
  }

  &>div{
    display: flex;
    gap:1rem;
    svg{
      fill:white !important;
      stroke: none !important;
    }
    &>{
      div{
        display: flex;
        flex-direction: column;
  justify-content: ${props => props.center && 'center'};
        gap:.2rem;
      }
    }
  }
`

interface ISquareIcon {
  color?: string
}

export const SquareIcon = styled.div<ISquareIcon>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 40px;
  aspect-ratio: 1/1 !important;
  background-color: ${props => props.color ? props.color : '#ff9900'};
  svg{
    fill: white;
  }
`

export const DCTitle = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const MainDashboardCard = styled(DashboardCard)`
  width: 50%;
  background:white;
  overflow: auto;
  @media (max-width: 500px) {
  height: max-content;
  }

  
`

interface IDCTaskCount {
  noBottom?: boolean
  flex?: boolean
  width?: string
}

export const DCTaskCount = styled.div<IDCTaskCount>`
  width:100%;
  display: flex;
  flex-direction: column;
  ${props => !props.noBottom && 'border-bottom: .45px solid #00000030;'}
  padding: .5rem 0;
  font-size: .8rem;
  align-items: center;
  ${props => props.flex && 'display:flex; width:100%; justify-content:space-between; flex-direction:row;'}
  ${props => props.width && 'width:' + props.width};
`

interface IDCContent {
  grid?: boolean
  gap?: string
  noGap?: boolean
  width?: string
  height?: string
  background?: boolean
  padding?: string
}

export const DCContent = styled.div<IDCContent>`
  height: ${props => props.height ?? '80%'};
  display: flex;
  align-items: center;
  justify-content: ${props => props.noGap ? 'auto' : 'space-between'};
  flex-direction: column;
  grid-template-rows: auto;
  ${props => props.padding && 'padding: ' + props.padding};
  ${props => props.grid && 'display: grid; gap: 1rem; grid-template-columns: repeat(3, 1fr);'}
  ${props => props.gap && 'gap:' + props.gap + ';'}
  ${props => props.width && 'width:' + props.width + ';'}
  ${props => props.background && `
     border-radius:10px;
      box-shadow: 8.33482551574707px 20.003582000732422px 45.008056640625px 0px #07063D0D;
  `}
`



export const AlertCard = styled.div`
width: 100%;
cursor:pointer;
height: 100%;
background-color: white;
box-sizing: border-box;
border-radius: 15px;
display: flex;
gap:.3rem;
box-shadow: 0px 0px 25px 0px #00000810;
align-items: flex-start;
justify-content: space-between;
transition: .1s ease;
padding: .8rem;

&:hover{
  background-color: ${props => props.theme.colorRed};
  h1,p{
    color:white
  }
  svg path{
    stroke:white
  }
}

h1{
  font-size: 2rem;
  color:${props => props.theme.colorRed}
}

p{
  font-size: .8rem ;
}

span{
  rotate: 180deg;

  svg{
    transform: scale(1.3);
  }
}
`

interface IDCLineItem extends IDCTaskCount {
  warning?: boolean
  borderTop?: boolean
}

export const DCLineItem = styled.div<IDCLineItem>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => !props.noBottom && 'border-bottom: .45px solid #00000030;'}
  ${props => props.borderTop && 'border-top: .45px solid #00000030;'}

  >div{
    display: flex;
    gap:1rem;
    padding: .5rem 0;
    font-size: .9rem;

    svg{
      fill: ${props => props.warning ? props.theme.colorPink : props.theme.greenChart} !important;
    }
  }
`

interface IChartTooltip {
  color?: string
}

export const ChartTooltip = styled.div<IChartTooltip>`

  width: 8rem;
  height: 4rem;
  background-color: white;
  border: none;
  outline:none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: .4rem;
  flex-direction: column;
  box-shadow: 0px 4px 20px 0px #00000026;


  &>div{

    display: flex;
    font-size: 1rem;
    gap:.3rem;
    align-items: center;
    h3{
      color: ${props => props.color ?? '#ff9900'};
    }
  }

`

export const MachineryCardCount = styled.div`
  
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;

  h3{
    font-size: 2rem;
    color:#ff9900
  }
`

export const DCBarTooltip = styled.div`
  min-width: 8rem;
  width: max-content;
  height: 4rem;
  background-color: white;
  border: none;
  outline:none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: .4rem;
  flex-direction: column;
  box-shadow: 0px 4px 20px 0px #00000026;
`

interface IDCFinancesCard {
  color?: string
  plain?: boolean
}
export const DCFinancesCard = styled.div<IDCFinancesCard>`
  width:33%;
  height: 3rem;
  padding: .2rem .5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #F5F5F5;
  border-radius: 7px;
  font-size: .8rem;
  ${props => props.plain && 'flex-direction:row; border:none; width:100%;'}

  h3{
    color: ${props => props.color ?? '#ff9900'};
  }

  @media (max-width: 500px) {
  width: 100%;
  }


  

`

export const AreaTooltip = styled.div<IDCFinancesCard>`
width:max-content;
height:max-content;
padding:1rem;
background-color: white;
border-radius: 10px;
position: relative;
font-size: .8rem;

span{
  font-size: 1rem;
}

.time{
  position: absolute;
  bottom:.5rem;
  right:.5rem;
  p{
    font-size: 1.1rem;
  }



}


`