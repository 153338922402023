import { GreenCheckIcon } from 'assets/icons/CheckCheckIcon'
import { WarningCircle } from 'assets/icons/WarningCircle'
import { DCLineItem } from 'styling/GlobalStyles'

interface ILineItem {
    title: string
    count: number
    warning?: boolean
    noBottom?: boolean
    borderTop?: boolean
    numberOnly?: boolean
}
export const LineItem = ({ title, count, warning, noBottom, borderTop, numberOnly }: ILineItem) => {
    return <DCLineItem noBottom={noBottom} warning={warning} borderTop={borderTop}>
        <p>{title}</p>
        <div>
            <p className='bold' style={numberOnly && { color: '#FF5B5B' }}>{count}</p>
            {!numberOnly && (!warning ? GreenCheckIcon : WarningCircle)}
        </div>
    </DCLineItem>
}