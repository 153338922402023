/* eslint-disable @typescript-eslint/no-empty-function */
import { CloseIcon } from 'assets/icons/CloseIcon'
import { financesIcon } from 'assets/icons/financesIcon'
import { Title } from 'components/Dashboard/Title'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { RootState } from 'store'
import { AreaTooltip, DashboardCard, DCFinancesCard, Flex, P } from 'styling/GlobalStyles'
import { getAmPm } from 'utils/helpers'
import { WidgetEditOverlay } from '../Dashboard.styled'
import { IWidget } from 'types/dashboard.interface'

export const FinancesWidget = ({
    modalOpen,
    removeWidget,
    onDragStart,
    onDragOver,
    onDragDrop,
}: IWidget) => {
    const navigate = useNavigate()
    const { finances } = useSelector((state: RootState) => state.dashboard)
    const financesData = finances?.hourlyFinances.map((f) => ({
        name: getAmPm(new Date(f.hour)),
        actualCost: f.hourlyActualCost,
        estimatedCost: f.hourlyEstimatedCost,
    }))

    return (
        <DashboardCard
            draggable={modalOpen}
            onDragStartCapture={() => {
                onDragStart('finances', 1)
            }}
            onDrop={onDragDrop}
            onDragEnd={(e) => {
                e.preventDefault()
            }}
            onDragOver={(e) => {
                e.preventDefault()
                onDragOver('finances')
            }}
            width='1fr'
            style={{ gridArea: 'finances', cursor: modalOpen ? 'grab' : 'normal' }}
        >
            {modalOpen && (
                <WidgetEditOverlay
                    onClick={(e) => {
                        removeWidget('finances')
                        e.stopPropagation()
                    }}
                >
                    <P>Remove Widget</P>
                    <CloseIcon white />
                </WidgetEditOverlay>
            )}
            <Title
                mainIcon={financesIcon}
                title='Finances'
                subtitle='Financial Overview'
                arrow
                onClick={() => !modalOpen && navigate('/dashboard/tasks')}
            />
            <Flex width='100%'>
                <DCFinancesCard plain color='#1CBBFF'>
                    <p>Total cost:</p>
                    <h3>${finances?.totalActualCost.toFixed(2)}</h3>
                </DCFinancesCard>
            </Flex>
            <ResponsiveContainer width='100%' height='100%'>
                <AreaChart
                    width={600}
                    height={400}
                    data={financesData}
                    margin={{
                        top: 0,
                        right: 0,
                        left: -20,
                        bottom: -10,
                    }}
                >
                    <defs>
                        <linearGradient
                            id='actual'
                            x1='229.5'
                            y1='-18.3341'
                            x2='229.5'
                            y2='206.125'
                            gradientUnits='userSpaceOnUse'
                        >
                            <stop offset='0.5' stopColor='#1CBBFF' />
                            <stop offset='1' stopColor='#1CBBFF' stopOpacity='0.1' />
                        </linearGradient>
                        <linearGradient
                            id='estimated'
                            x1='229.5'
                            y1='-18.3341'
                            x2='229.5'
                            y2='206.125'
                            gradientUnits='userSpaceOnUse'
                        >
                            <stop offset='0.5' stopColor='#ff9900' />
                            <stop offset='1' stopColor='#ff9900' stopOpacity='0.1' />
                        </linearGradient>
                    </defs>

                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='name' fontSize='.7rem' />
                    <YAxis fontSize='.7rem' />
                    <Tooltip
                        wrapperStyle={{ outline: 'none' }}
                        content={(props) => {
                            const actualCost = props.payload?.find((p) => p.name == 'actualCost')
                            // const estimated = props.payload?.find(p => p.name == 'estimatedCost')
                            return (
                                <AreaTooltip>
                                    <div className='time'>
                                        <p>{props?.label}</p>
                                    </div>
                                    {/* <Flex column >
                        <p>Estimated Cost:</p>
                        <span style={{ color: '#ff9900' }} >${estimated?.value}</span>
                    </Flex> */}
                                    <Flex column m='2rem 0'>
                                        <p>Actual Cost:</p>
                                        <span style={{ color: '#1CBBFF' }}>${actualCost?.value}</span>
                                    </Flex>
                                </AreaTooltip>
                            )
                        }}
                    />

                    {/* <Area type='monotone' dataKey='estimatedCost'
                stroke='#fadcafcf'
                strokeWidth={2}
                strokeOpacity={0.9}
                fill='url(#estimated)'
                fillOpacity='1'
            /> */}
                    <Area
                        type='monotone'
                        dataKey='actualCost'
                        stroke='#53c3f373'
                        strokeWidth={2}
                        strokeOpacity={0.9}
                        fill='url(#actual)'
                        fillOpacity='.8'
                    />
                </AreaChart>
            </ResponsiveContainer>
        </DashboardCard>
    )
}
