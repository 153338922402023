import { baseUrl } from './../../utils/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IIssuesSlice, ISingleIssue } from 'types/issues.interface'
import { toast } from 'react-toastify'
import { handleErrors, formatParams } from 'utils/helpers'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { INameId } from 'constants/globalTypes'
import { IIssuesPostPatchRequest, IIssuesSingleResponse } from 'types/api/issuesApi.interface'

const initialState:IIssuesSlice = {
  isLoading: false,
  issues: [],
  singleIssue: null,
  noIssues: false,
  totalCount: 0,
  totalPages: 0,
}

export const getIssues = createAsyncThunk('issues/getIssues', async (params: any, thunkAPI) => {
  params = formatParams(params)
  try {
    const resp:AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get('issues', { params })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const addIssue = createAsyncThunk(
  'issues/addIssue',
  async (issue: ISingleIssue, thunkAPI) => {
    try {
      const obj:IIssuesPostPatchRequest = { name: issue.name, industryId: issue.industry.id }
      const resp = await baseUrl.post('issues', obj, thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const deleteIssue = createAsyncThunk('issues/deleteIssue', async (id: number, thunkAPI) => {
  try {
    await baseUrl.delete('issues/' + id, thunkAPI)

    return id
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getSingleIssue = createAsyncThunk(
  'issues/getSingleIssue',
  async (id: number | string, thunkAPI) => {
    try {
      const resp:AxiosResponse<IIssuesSingleResponse> = await baseUrl.get('issues/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateIssue = createAsyncThunk(
  'issues/updateIssue',
  async (issue: ISingleIssue, thunkAPI) => {
    try {
      const obj:IIssuesPostPatchRequest = { name: issue.name, industryId: issue.industry.id }
      const resp = await baseUrl.patch('issues/' + issue.id, obj, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    clearIssue: (state) => {
      state.singleIssue = null
    },
    clearIssuesList: (state) => {
      state.issues = []
      state.totalCount = null
      state.totalPages = null
    },
  },
  extraReducers: {
    [getIssues.pending.type]: (state) => {
      state.isLoading = true
    },
    [getIssues.fulfilled.type]: (state:IIssuesSlice, { payload }:{payload:IPaginatedResponse<INameId>}) => {
      state.isLoading = false
      state.issues = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noIssues = state.issues.length < 1
    },
    [getIssues.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addIssue.pending.type]: (state) => {
      state.isLoading = true
    },
    [addIssue.fulfilled.type]: (state:IIssuesSlice, { payload }:{payload:IIssuesSingleResponse}) => {
      state.isLoading = false
      if (state.issues.length < 30) {
        state.issues = [...state.issues, payload]
        state.noIssues = false
      }
      toast.success('Issue added')
    },
    [addIssue.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleIssue.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleIssue.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleIssue = payload
      console.log(payload)
    },
    [getSingleIssue.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateIssue.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateIssue.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleIssue = payload
      const index = state.issues.findIndex((is) => is.id === payload.id)
      if (index) {
        state.issues[index] = payload
      }
      toast.success('Issue updated')
    },
    [updateIssue.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteIssue.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false

      state.issues = state.issues.filter((item) => item.id !== payload)
      state.noIssues = state.issues.length < 1
      toast.success('Issue deleted')
    },
    [deleteIssue.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export default issuesSlice.reducer
export const { clearIssue, clearIssuesList } = issuesSlice.actions
