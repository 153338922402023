import React from 'react'
import { InputWrapper, Label, LoginPasswordField } from './Input.styled'

export const LoginPassword = ({ password, setState, field, labelText }) => {

    const getStyle = palce => {
        if (password[palce]) {
            return { boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)', background: 'white', border: '1px solid transparent' }
        }
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target
        if (value.length > 1) {
            return
        }
        setState(prev => {
            const copy = structuredClone(prev)
            copy[field][name] = value
            return copy
        }
        )
        if (e.target.nextElementSibling && value.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //  @ts-ignore
            e.target.nextElementSibling.focus()
        }

    }

    const focusPrevious = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Backspace' && !e.currentTarget.value) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //  @ts-ignore
            e.currentTarget.previousElementSibling?.focus()
        }
    }

    return (
        <InputWrapper
            nomarg={true}
        >
            <Label labelText={labelText} />
            <LoginPasswordField  >
                <input type='number' onKeyDown={focusPrevious} onChange={handleChange} name='0' value={password[0] || ''} style={getStyle(0)} />
                <input type='number' onKeyDown={focusPrevious} onChange={handleChange} name='1' value={password[1] || ''} style={getStyle(1)} />
                <input type='number' onKeyDown={focusPrevious} onChange={handleChange} name='2' value={password[2] || ''} style={getStyle(2)} />
                <input type='number' onKeyDown={focusPrevious} onChange={handleChange} name='3' value={password[3] || ''} style={getStyle(3)} />
                <input type='number' onKeyDown={focusPrevious} onChange={handleChange} name='4' value={password[4] || ''} style={getStyle(4)} />
                <input type='number' onKeyDown={focusPrevious} onChange={handleChange} name='5' value={password[5] || ''} style={getStyle(5)} />
            </LoginPasswordField>
        </InputWrapper>
    )
}