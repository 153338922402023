
/* eslint-disable*/
// @ts-nocheck TOOD
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import { FieldsModal } from 'components/Modal/FieldsModal'
import { Modal } from 'components/Modal/Modal'
import { NavBar } from 'components/NavBar/NavBar'
import { TextDisplayCard } from 'components/TextDisplayCard/TextDisplayCard'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import {
    Flex,
    Button,
    Separator,
    H2,
    PageWrapper,
    H1WithBack,
    P1,
    ItemsGrid,
} from 'styling/GlobalStyles'
import { IDropDownItem } from 'types/fields.interface'
import { clearWorkerComponent, getSingleWorkerComponent } from 'features/workerComponents/workerComponentsSlice'
import { workerComponentTypes } from './workerComponentsData'

export const SingleWorkerComponent = () => {
    const [modal, setModal] = useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const { singleWorkerComponent, noSubfields } = useSelector((store: RootState) => store.workerComponents)
    const { id } = useParams()

    useEffect(() => {
        dispatch(getSingleWorkerComponent(Number(id)))

        return () => {
            dispatch(clearWorkerComponent())
        }
    }, [])

    const dataTypes = ['Text', 'Number', 'DropDown']

    return (
        <>
            <NavBar />

            <PageWrapper>
                <Flex between>
                    <H1WithBack title={singleWorkerComponent?.name} arrow />
                    <Button orange onClick={() => setModal(true)}>
                        Edit Component
                    </Button>
                </Flex>
                <Separator />
                <H2 mb='1'>Field</H2>
                <Flex gap='1rem' center>
                    <TextDisplayCard width='15rem' title={singleWorkerComponent?.name} labelText='Field name' />
                    {singleWorkerComponent?.unit?.name && (
                        <TextDisplayCard title={singleWorkerComponent?.unit?.name} labelText='Field unit' />
                    )}
                    <TextDisplayCard title={dataTypes[singleWorkerComponent?.dataType - 1]} labelText='Data type' />
                    <TextDisplayCard title={workerComponentTypes?.find(t => t.value == singleWorkerComponent?.componentType)?.label} labelText='Data type' />
                </Flex>
                <Flex gap='1rem' m='2rem 0' column>
                    <H2>Subfields</H2>
                    {!noSubfields ? (
                        singleWorkerComponent?.dropDownItems?.map((item: IDropDownItem) => {
                            return (
                                <ItemsGrid columns={2} gap='1rem' key={item.id} width='60%'>
                                    <TextDisplayCard labelText='Data type' title={dataTypes[item.dataType - 1]} />
                                    <TextDisplayCard labelText='Value' title={item.name} />
                                </ItemsGrid>
                            )
                        })
                    ) : (
                        <P1>No Subfields</P1>
                    )}
                </Flex>
            </PageWrapper>
            {modal && (
                <Modal black noMaxH>
                    <FieldsModal close={setModal} workerComponents field={singleWorkerComponent} edit={true} />
                </Modal>
            )}
        </>
    )
}
