import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer'
import { editUserPosition, getGlobalViewMachines, getGlobalViewUsers } from 'features/globalView/globalViewSlice'
import { getCenter } from 'pages/Zones/zoneConstants'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import Avatar from '../../assets/images/profileAvatar.png'
import machineMapIcon from '../../assets/images/machineMapIcon.png'
import { GlobalViewMapContainer } from './GlobalView.styled'
import { IGLSocketResponse } from 'types/global-view.interface'
import { fetchAuthSession } from 'aws-amplify/auth'

export const GlobalViewMap = ({ select, map, setMap, filters, loadedPolygons, usersCluster, handleGetInfo, machinesCluster }) => {
    const ref = useRef()
    const dispatch = useDispatch<AppDispatch>()
    const { users, areas, machines } = useSelector((state: RootState) => state.globalView)
    const userMarkers = useRef([])
    const machinesMarkers = useRef([])
    const [centerSet, setCenterSet] = useState(false)

    useEffect(() => {

        let ws
        const setupWebSocket = async () => {
            try {
                ws = new WebSocket(process.env.REACT_APP_WS + ((await fetchAuthSession()).tokens.accessToken.toString()));
                ws.onopen = () => {
                    console.log('connected')
                    console.log(users)
                    console.log(users.filter(u => u.position).map(u => 'user-' + u.id))
                    const message = JSON.stringify({
                        action: 'subscribeToDevicePositions',
                        deviceIds: users.filter(u => u.position).map(u => 'user-' + u.id)
                    });

                    try {
                        ws.send(message);

                    } catch (error) {
                        console.log(error)
                    }
                };

                ws.onmessage = (event) => {
                    const data: IGLSocketResponse = JSON.parse(event.data);
                    console.log(data)
                    if (data.type === 'devicePositionUpdate') {
                        dispatch(editUserPosition({
                            id: data.payload.DeviceId,
                            position: {
                                longitude: data.payload.Position[0],
                                latitude: data.payload.Position[1]
                            }
                        }));
                    }
                };

                // Handle connection close and errors (optional)
                ws.onclose = () => {
                    console.log('WebSocket connection closed');
                };

                ws.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };

                // Clean up when component unmounts
                return () => {
                    ws.close();
                };
            } catch (error) {
                console.error('Error fetching AWS access token:', error);
            }
        };

        setupWebSocket();
        return () => {
            ws.close();
        };
    }, [users]);


    const mapOptions = {
        zoom: 2,
        mapId: '1ad8431b024d9e03',
        center: {
            lat: 0,
            lng: -0,
        },
        disableDefaultUI: true,
        mapTypeId: 'hybrid',
        styles: [
            {
                featureType: 'road',
                stylers: [
                    { visibility: 'off' }
                ]
            }
        ]
    }

    const markerStyle = ({ position, count }, color) => {
        const svg = window.btoa(`
        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle cx="120" cy="120" opacity=".8" r="70" />    
        </svg>`);
        return new google.maps.Marker({
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(75, 75),
            },
            label: {
                text: String(count),
                color: '#fff',
                fontSize: '15px',
                fontWeight: 'bold',
            },
            // adjust zIndex to be above other markers
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
    }

    useEffect(() => {
        if (map && !centerSet) {
            const pos = users?.find(u => u.position)
            if (users?.length > 0 && pos && filters.users) {
                map.setZoom(15)
                map.setCenter({ lat: pos.position.latitude, lng: pos.position.longitude });
                setCenterSet(true)
            } else if (areas.length > 0 && filters.areas) {
                const center = getCenter(areas[0])
                map.setZoom(15)
                map.setCenter(center)
                setCenterSet(true)
            }
        }
    }, [map, users, areas])

    useEffect(() => {
        const runEffect = () => {

            const machinesStatus = select.selected !== 'ALL' ? (select.selected === 'IDLE' ? 2 : 1) : null;
            dispatch(getGlobalViewMachines({ status: machinesStatus, initialLoading: machines.length < 0 }));
            // if (usersCluster) {
            //     usersCluster.current?.setMap(null);
            //     usersCluster.current = null;
            // }
            // if (machinesCluster) {
            //     machinesCluster.current?.setMap(null);
            //     machinesCluster.current = null;
            // }
        };

        runEffect()

        const interval = setInterval(runEffect, 5000); // Then run every 5 seconds

        const usersStatus = select.selected !== 'ALL' ? (select.selected === 'IDLE' ? 'Free' : 'Busy') : null;
        dispatch(getGlobalViewUsers({ status: usersStatus, initialLoading: users.length < 0 }));



        return () => clearInterval(interval); // Clear interval on component unmount
    }, [select.selected, machinesCluster]);

    useEffect(() => {
        loadedPolygons.current?.forEach(lp => {
            lp.polygon.setMap(null)
            lp.label.setMap(null)
        })
        loadedPolygons.current = []
        if (filters.areas) {
            loadedPolygons.current = areas.map((a) => {
                const center = getCenter(a)
                const content = document.createElement('p')
                content.innerText = a.areaName
                content.className = 'white-text'

                const polygon = new google.maps.Polygon({
                    paths: a.path,
                    fillColor: '#fff',
                    map,
                    strokeWeight: 0.5,
                    clickable: true,
                })

                polygon.addListener('click', () => handleGetInfo({ path: a.path, areaName: a.areaName, areaSize: a.areaSize, id: a.id }))

                return {
                    id: a.id,
                    label: new google.maps.marker.AdvancedMarkerElement({
                        position: { lat: Number(center.lat), lng: Number(center.lng) },
                        map,
                        content,
                    }),
                    polygon,
                }
            })
        }
    }, [areas])

    useEffect(() => {
        setMap(new google.maps.Map(ref.current, mapOptions))
    }, [])

    useEffect(() => {
        if (map && filters.users) {
            usersCluster.current?.setMap(null)
            usersCluster.current = null
            userMarkers.current.map(m => m.setMap(null))
            userMarkers.current = []
            const customMarker = document.createElement('div')
            customMarker.className = 'test'
            let imageBroken = false
            userMarkers.current = users
                ?.filter((u) => u.position)
                .map(({ name, taskName, position, profileImageUrl }) => {
                    const infoWindow = new google.maps.InfoWindow()
                    const content = document.createElement('div')
                    const imageContent = document.createElement('img')
                    const text = document.createElement('p')
                    text.innerText = name
                    content.className = 'marker'
                    imageContent.className = 'marker-image'
                    imageContent.src = profileImageUrl
                    imageContent.onerror = () => {
                        imageContent.onerror = null
                        imageContent.src = Avatar
                        imageBroken = true
                    }
                    content.appendChild(imageContent)
                    content.appendChild(text)

                    const marker = new google.maps.marker.AdvancedMarkerElement({
                        position: { lat: Number(position.latitude), lng: Number(position.longitude) },

                        map,
                        content,
                    })

                    marker.addListener('gmp-click', () => {
                        infoWindow.setPosition({
                            lat: Number(position.latitude),
                            lng: Number(position.longitude),
                        })
                        infoWindow.setContent(`
                            <div class='info-window'>
                            <div class='image-container'>
                                <img src='${imageBroken ? Avatar : profileImageUrl
                            }'  alt='task-image' />
                            </div>
                            <div class='data-container'>
                                <h3>${name}</h3>
                                <p>${taskName || ''}</p>
                            </div>
                            </div>
                        `)
                        infoWindow.open({ map })
                    })
                    return marker
                })

            usersCluster.current = new MarkerClusterer({
                markers: userMarkers.current,
                map,
                algorithm: new SuperClusterAlgorithm({ radius: 200 }),
                renderer: ({
                    render: ({ count, position }) => markerStyle({ count, position }, '#00A368')
                })
            })
        }
    }, [users, map])

    useEffect(() => {
        if (map) {
            if (filters.machines) {
                machinesCluster.current?.setMap(null)
                machinesCluster.current = null
                machinesMarkers.current.map(m => m.setMap(null))
                machinesMarkers.current = []
                const customMarker = document.createElement('div')
                customMarker.className = 'test'
                let imageBroken = false
                machinesMarkers.current = machines
                    .filter((m) => m.vehicle?.latitude)
                    .map(({ name, taskName, vehicle, imageUrl }) => {
                        const infoWindow = new google.maps.InfoWindow()
                        const content = document.createElement('div')
                        const imageContent = document.createElement('img')
                        const text = document.createElement('p')
                        text.innerText = name
                        content.className = 'marker'
                        imageContent.className = 'marker-image'
                        imageContent.src = imageUrl
                        imageContent.onerror = () => {
                            imageContent.onerror = null
                            imageContent.src = machineMapIcon
                            imageBroken = true
                        }
                        content.appendChild(imageContent)
                        content.appendChild(text)

                        const marker = new google.maps.marker.AdvancedMarkerElement({
                            position: { lat: Number(vehicle.latitude), lng: Number(vehicle.longitude) },
                            map,
                            content,
                        })

                        marker.addListener('gmp-click', () => {
                            infoWindow.setPosition({
                                lat: Number(vehicle.latitude),
                                lng: Number(vehicle.longitude),
                            })
                            infoWindow.setContent(`
                                <div class='info-window'>
                                <div class='image-container'>
                                    <img src='${imageBroken ? machineMapIcon : vehicle
                                }'  alt='task-image' />
                                </div>
                                <div class='data-container'>
                                    <h3>${name}</h3>
                                    <p>${taskName || ''}</p>
                                </div>
                                </div>
                            `)
                            infoWindow.open({ map })
                        })
                        return marker
                    })

                machinesCluster.current = new MarkerClusterer({
                    markers: machinesMarkers.current,
                    map,
                    algorithm: new SuperClusterAlgorithm({ radius: 200 }),
                    renderer: ({
                        render: ({ count, position }) => markerStyle({ count, position }, '#ff9900')
                    })
                })

            }
        }


    }, [map, machines])

    return <GlobalViewMapContainer ref={ref} id='map' />
}
