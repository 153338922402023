import React, { useState } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import { Options } from 'components/Options/Options'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  MainHeadline,
  PageControls,
  PageFooter,
  PageWrapper,
  Separator,
  Table,
  Th,
  Tr,
} from 'styling/GlobalStyles'
import { Td } from 'components/Td/Td'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { clearLocationsList, deleteLocation, getLocations } from 'features/locations/locationsSlice'
import { ILocationState } from 'types/locations.interface'
import { setConfirmModal } from 'features/global/globalSlice'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Pagination } from 'components/Pagination/Pagination'
import { getParams, handlePagination } from 'utils/helpers'
import { ModalTypes } from 'types/global.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const Locations = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { locations, noLocations, totalPages } = useSelector((store: RootState) => store.locations)
  const [params, setParams] = useState<any>(getParams({ location, limit: 5 }))

  useQueryPagination({ fetchFunctions: [getLocations], cleanUpFunctions: [clearLocationsList], params })

  const removeLocation = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteLocation(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: locations.length < 2,
        },
        word: 'delete location',
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} placeholder='Search locations...' input setParams={setParams} limit={5} />
      <PageWrapper>
        <PageControls>
          <MainHeadline>Locations</MainHeadline>
          <Button orange onClick={() => navigate('add')}>
            Add Location
          </Button>
        </PageControls>
        <Separator />
        {noLocations ? (
          <NoItems title='Locations' />
        ) : (
          <Table>
            <Th>
              <Td w='20%' noTooltip text='Location Name' />
              <Td w='20%' noTooltip text='Location Type' />
              <Td w='20%' noTooltip text='Latitude' />
              <Td w='20%' noTooltip text='Longitude' />
              <Td w='20%' noTooltip text='Area' />
              <Td w='5%' />
            </Th>
            {locations &&
              locations.map((item: ILocationState) => {
                return (
                  <Tr key={item.id}>
                    <Td w='20%' text={item.name} />
                    <Td w='20%' text={item.type} />
                    <Td w='20%' text={item.coordinates.latitude.toFixed(2)} />
                    <Td w='20%' text={item.coordinates.longitude.toFixed(2)} />
                    <Td w='20%' text={item.areaSize} />
                    <Td w='5%' noTooltip>
                      <Options item={item} title='Location' removeLocation={removeLocation} />
                    </Td>
                  </Tr>
                )
              })}
          </Table>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 5)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
