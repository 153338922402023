import React, { useState, useEffect, useRef } from 'react'
import {
  Flex,
  CursorPointer,
  AddButton,
  Separator,
  SelectImageContainer,
  Button,
  ImageUpload,
  H1,
} from '../../styling/GlobalStyles'
import { Input } from '../Input/Input'
import { AddCircle } from '../../assets/icons/AddCircle'
import { CloseIcon } from '../../assets/icons/CloseIcon'
import { useDispatch } from 'react-redux'
import {
  addCategory,
  deleteCategory,
  updateCategory,
} from '../../features/categories/categoriesSlice'
import { getBase64 } from '../../utils/helpers'
import { setConfirmModal } from 'features/global/globalSlice'
import { AppDispatch } from 'store'
import {
  addInventoryCategory,
  deleteInventoryCategory,
  updateInventoryCategory,
} from 'features/inventory/invSettingsCategoriesSlice'
import { ICategotyModal } from 'constants/globalTypes'
import {
  addMachineryCategory,
  deleteMachineryCategory,
  updateMachineryCategory,
} from 'features/categories/machineryCategories'
import useFocusFirstInput from 'utils/useFocusFirstInput'

export const CategoryModal = ({
  close,
  edit,
  text,
  id,
  image,
  machinery,
  fields,
  invSettings,
  setParams,
}: ICategotyModal) => {
  const dispatch = useDispatch<AppDispatch>()
  const [name, setName] = useState(text)
  const [imageParsed, setImageParsed] = useState(image)
  const modalRef = useRef<HTMLDivElement>(null)


  console.log(fields)

  useFocusFirstInput()

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        event.preventDefault()
        close({ open: false })
      }
    }
    const outSideClick = (e) => {
      if (!modalRef.current.contains(e.target)) {
        close({ open: false })
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('mousedown', outSideClick)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('mousedown', outSideClick)
    }
  }, [])

  const handleDelete = () => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: () => {
          dispatch(
            machinery
              ? deleteMachineryCategory(Number(id))
              : invSettings
                ? deleteInventoryCategory(Number(id))
                : deleteCategory(Number(id)),
          )
          dispatch(setConfirmModal({ isOpen: false }))
        },
        word: 'delete category',
        setParams: setParams,
      }),
    )
    close({ open: false })
  }

  const handleSubmit = async () => {
    let resp: any
    if (machinery) {
      const clientId = 1
      if (edit) {
        const obj = { name, image: imageParsed, clientId, id, localImage: null }
        if (obj.image && obj.image.startsWith('http')) {
          delete obj.image
          obj.localImage = imageParsed
        }
        resp = await dispatch(updateMachineryCategory(obj))
      } else {
        resp = await dispatch(addMachineryCategory({ name, image: imageParsed, clientId }))
      }
    } else if (invSettings) {
      if (edit) {
        const obj = { name, image: imageParsed, id: Number(id), localImage: null, fields: [] } //  TODO: Kad bekend resi fieldove, pogledati sta se salje umesto praznog
        if (obj.image && obj.image.startsWith('http')) {
          delete obj.image
          obj.localImage = imageParsed
        }
        resp = await dispatch(updateInventoryCategory(obj))
      } else {
        resp = await dispatch(addInventoryCategory({ name }))
      }
    } else {
      if (edit) {
        const obj = { name, image: imageParsed, id, fields, localImage: null }
        if (obj.image && obj.image.startsWith('http')) {
          delete obj.image
          obj.localImage = imageParsed
        }
        resp = await dispatch(updateCategory(obj))
      } else {
        resp = await dispatch(addCategory({ name, image: imageParsed }))
      }
    }
    if (resp.meta.requestStatus === 'fulfilled') {
      close({ open: false })
    }
  }

  return (
    <div>
      <Flex column ref={modalRef}>
        <Flex between width='100%'>
          <H1>{edit ? 'Edit ' + text : 'Add Category'}</H1>
          <CursorPointer onClick={() => close({ open: false })}>
            <CloseIcon />
          </CursorPointer>
        </Flex>
        <Separator />
        <Flex gap='1rem' between width='100%' height='10rem'>
          {!invSettings && !machinery && !imageParsed ? (
            <ImageUpload>
              <AddButton
                width='180px'
                height='130px'
                onClick={(e: any) => {
                  e.target.parentElement.children[1].click()
                }}
              >
                <AddCircle /> Add image
              </AddButton>
              <input
                type='file'
                name='imageupload'
                accept='image/*'
                onChange={(e) => {
                  getBase64(e.target.files[0]).then((res: string) => setImageParsed(res))
                }}
              />
            </ImageUpload>
          ) : (
            <ImageUpload>
              <SelectImageContainer
                style={{ cursor: 'pointer' }}
                onClick={(e: any) => {
                  e.target.parentElement.parentElement.children[1].click()
                }}
              >
                <img src={imageParsed} alt='' />
              </SelectImageContainer>
              <input
                type='file'
                name='imageupload'
                accept='image/*'
                onChange={(e) => {
                  getBase64(e.target.files[0]).then((res: string) => setImageParsed(res))
                }}
              />
            </ImageUpload>
          )}
          <Flex column between ml='10px' width={invSettings || machinery ? '100%' : '60%'}>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              labelText='Title'
              type='text'
              placeholder='Enter category title'
            />

            {edit ? (
              <Flex>
                <Flex width='100%' between>
                  <Button pink width='135' onClick={handleDelete}>
                    Delete
                  </Button>
                  <Button
                    orange
                    width='135'
                    disabled={!name || name.length < 1}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <Flex width='100%' end='true'>
                <Button disabled={!name || name.length < 1} orange onClick={handleSubmit}>
                  Save
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
