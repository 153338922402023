import { Input } from 'components/Input/Input'
import { Modal } from 'components/Modal/Modal'
import { useState } from 'react'
import { Button, CursorPointer, Flex, H1, InvalidText } from 'styling/GlobalStyles'
/*eslint-disable*/
import { setState } from 'utils/helpers'
import { LoginPassword } from 'components/Input/LoginPassword'
import { toast } from 'react-toastify'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { setLoadingAuth } from 'features/global/globalSlice'
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth'

interface IPasswordResetModal {
    close: any
}
export const PasswordResetModal = ({ close }: IPasswordResetModal) => {
    const [first, setFirst] = useState(true)
    const [inputs, setInputs] = useState({ username: '', code: [], password: [], newPasswordVerify: [] })
    const [error, setError] = useState('')
    const dispatch = useDispatch<AppDispatch>()


    const handleChange = e => {
        const { value, name } = e.target
        setState(setInputs, name, value)
        if (error) {
            setError('')
        }
    }

    const handleSubmit = async () => {
        if (first && !inputs.username) {
            toast.warn('Username must be provided.')
            return
        } else if (!first && (inputs.code.length < 6 || inputs.password.length < 6 || inputs.newPasswordVerify.length < 6)) {
            toast.warn('All fields must be valid.')
            return
        }
        dispatch(setLoadingAuth(true))

        if (first) {
            try {
                await resetPassword({ username: inputs.username })
            } catch (error) {
                toast.error(error.message)
                return
            }
            dispatch(setLoadingAuth(false))
            setFirst(false)
        } else {
            if (inputs.password.join('') == inputs.newPasswordVerify.join('')) {
                try {
                    // await confirmResetPassword(inputs.username, inputs.code.join(''), inputs.password.join(''))
                    await confirmResetPassword({
                        username: inputs.username,
                        confirmationCode: inputs.code.join(''),
                        newPassword: inputs.password.join('')
                    })

                }
                catch (error) {
                    toast.error(error.message)
                    dispatch(setLoadingAuth(false))
                    return
                }
                dispatch(setLoadingAuth(false))
                handleClose()
                toast.success('Password successfully changed')
            } else {
                setError('Passwords do not match')
                dispatch(setLoadingAuth(false))
            }
        }
    }

    const handleClose = () => {
        setFirst(true)
        setInputs({ username: '', code: [], password: [], newPasswordVerify: [] })
        dispatch(setLoadingAuth(false))
        close()
    }


    return <Modal black zIndex='1' >
        <Flex column between tabIndex={1} onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}>
            <Flex width='100%' center between><H1>Password Reset</H1> <CursorPointer onClick={handleClose} ><CloseIcon black /></CursorPointer> </Flex>
            {!first && <p style={{ marginTop: '10px', fontStyle: 'italic' }} >Please enter the code you receive and a new password.</p>}
            <Flex mt='30' >
                {first ? <Flex column width='100%'>
                    <Input noinvalidtext invalid={!inputs.username} type='text' labelText='Username' placeholder='Enter username' name='username' value={inputs.username} onChange={handleChange} />
                    {error && <InvalidText >
                        {error}
                    </InvalidText>}
                </Flex> :
                    <Flex column gap='1rem' width='100%' >
                        <LoginPassword password={inputs.code} setState={setInputs} field='code' labelText="Code" />
                        <LoginPassword password={inputs.password} setState={setInputs} field='password' labelText="New password" />
                        <LoginPassword password={inputs.newPasswordVerify} setState={setInputs} field='newPasswordVerify' labelText="Confirm new password" />
                        {error && <InvalidText >
                            {error}
                        </InvalidText>}
                    </Flex>}

            </Flex>
            <Button orange mt='30' onClick={handleSubmit} >
                Submit
            </Button>
        </Flex>
    </Modal>
}