import React, { useState, useRef, useEffect } from 'react'
import { InvalidText } from 'styling/GlobalStyles'
import '../HonestSelect/HonestSelect.css'
import { fetchAuthSession } from '@aws-amplify/auth';
import axios from 'axios'
import Chevron from 'assets/icons/Chevron'
import { handleFocusNext, removeDuplicates } from 'utils/helpers'
import { ISelectValue } from 'constants/globalTypes'
import { IHonestAsyncSelect } from 'types/select.interface'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { Anchored, useOverlayContext } from 'OverlayContext'
export const HonestAsyncSelect = ({
  onChange,
  width = 'auto',
  defaultValue,
  link,
  name,
  placeholder,
  invalid,
  parserFunction,
  fixedList,
  multi,
  handleRemove,
  noPagination
}: IHonestAsyncSelect) => {
  const [openOptions, setOpenOptions] = useState(false)
  const [blured, setBlured] = useState(false)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState('')
  const [selectedOption, setSelectedOption] = useState(
    multi && defaultValue?.length > 0 ? defaultValue : defaultValue && !multi ? defaultValue : '',
  )
  const [options, setOptions] = useState([])
  const ref = useRef<HTMLInputElement>()
  const scrollContainerRef = useRef<HTMLDivElement>()
  const styleCheck = (!multi && selectedOption) || (multi && defaultValue?.length > 0)
  const shadow = styleCheck ? '0px 0px 30px rgba(0, 0, 0, 0.1)' : 'none'
  const border = blured && invalid ? '1px solid red' : styleCheck ? 'none' : '1px solid #c3c0cc'
  const [selected, setSelected] = useState(defaultValue ? true : false)
  const position = ref.current ? ref.current.getBoundingClientRect() : null
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { overlay } = useOverlayContext();

  let top = position ? position.top + 45 : null
  if (options && options.length >= 5 && top + 210 > window.innerHeight) {
    top = top - 250
  } else {
    top = top
  }
  const [selectedMulti, setSelectedMulti] = useState(
    multi && defaultValue
      ? defaultValue.map((item: ISelectValue) => ({ label: item.label, value: item.value }))
      : [],
  )

  useEffect(() => {

    if (parserFunction) {
      const fetchData = async () => {
        const session = await fetchAuthSession()
        link &&
          axios
            .get(
              process.env.REACT_APP_API +
              '/' +
              link + (!noPagination ?
                ('?page=' +
                  page +
                  '&limit=10' +
                  (query ? '&q=' + query : '')) : ''),
              { headers: { Authorization: `Bearer ${session.tokens.accessToken}` } },
            )
            .then((res) => {
              setOptions((prev) => {
                let copy = [...prev]
                copy = [...copy, ...parserFunction(res.data)].filter(({ label }) => label.toLowerCase().indexOf(query.toLowerCase()) > -1)
                if (noPagination) {
                  copy = copy.filter(({ label }) => label.toLowerCase().indexOf(query.toLowerCase()) > -1)
                }
                return removeDuplicates(copy)
              })
            })
      }
      fetchData()
    } else {
      searchFixedList()
    }
  }, [page, query])

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue)
      setSelected(true)
    }
  }, [defaultValue])

  const searchFixedList = () => {
    const list =
      fixedList &&
      fixedList.filter(({ label }) => label.toLowerCase().indexOf(query.toLowerCase()) > -1)
    setOptions(list)
  }

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollHeight, scrollTop, offsetHeight } = e.currentTarget
    const bottom = scrollTop + offsetHeight >= scrollHeight
    if (bottom && !noPagination) {
      setPage((prev) => prev + 1)
    }
  }

  const handleQuery = (e: React.FormEvent<HTMLInputElement>) => {
    setOptions([])
    const { value } = e.target as HTMLInputElement
    setSelectedOption(value)
    setQuery((prev: string) => {
      if (prev.length > value.length) {
        setSelected(false)
      }
      return value
    })
    if (query.length > value.length) {
      onChange({ label: '', value: null }, name)
    }
    setPage(1)
  }

  const handleChange = (value: ISelectValue) => {
    if (value.value) {
      if (multi) {
        setSelectedMulti((prev: ISelectValue[]) => {
          const copy = [...prev]
          copy.push(value)
          return copy
        })
      } else {
        setQuery(value.label)
        setSelectedOption(value.label)
      }
      onChange(value, name)
      setOpenOptions(false)
    }
  }

  const handleClear = () => {
    if (multi) {
      setSelectedMulti([])
      onChange({ label: 'CLEAR', value: null }, name)
    } else {
      onChange({ label: '', value: null }, name)
      setPage(1)
      setQuery('')
      setSelectedOption('')
      setSelected(false)
    }
  }

  const handleMultiRemove = (item: ISelectValue) => {
    setSelectedMulti((prev: ISelectValue[]) => {
      let copy = [...prev]
      copy = copy.filter((i) => i.value !== item.value)
      return copy
    })
    handleRemove(item)
  }

  let cName = 'honest-select-container'
  if ((!multi && selectedOption) || (multi && selectedOption.length > 0)) {
    cName = cName + ' active-select'
  }


  return (
    <div
      tabIndex={0}
      onBlur={() => {
        setBlured(true)
        setOpenOptions(false)
        !selected && setQuery('')
        overlay.style.pointerEvents = 'none'
      }}
      onClick={() => {
        overlay.style.pointerEvents = 'all'
        setOpenOptions((prev) => !prev)
      }}
      style={{
        width,
        minWidth: width,
        maxWidth: width,
        boxShadow: shadow,
        border,
        // background,
      }}
      className={cName}
      ref={anchorRef}
      onKeyDown={handleFocusNext}
    >

      {multi ? (
        <>
          {selectedMulti.length < 1 && <p>Select</p>}
          <div className='multi-container'>
            {selectedMulti.map((item: ISelectValue, key: number) => {
              return (
                <div key={key} className='multi-single'>
                  {item.label}
                  {selectedMulti.length > 0 && (
                    <div onMouseDown={() => handleMultiRemove(item)} className='close-container'>
                      <CloseIcon />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <input
          type='text'
          className='select-search'
          value={selectedOption}
          placeholder={placeholder ? placeholder : 'Select...'}
          onChange={handleQuery}
        />
      )}

      <div className='icons-container'>
        <Chevron />
        {selectedOption && (
          <span onClick={handleClear}>
            <CloseIcon />
          </span>
        )}
      </div>
      {openOptions && (
        <Anchored to={anchorRef} options={options.length} >
          <div
            className='honest-options-container'
            onScroll={handleScroll}
            ref={scrollContainerRef}
          >
            {options &&
              options
                .filter((item) => !selectedMulti.some((i: ISelectValue) => i.value == item.value))
                .map((item, key) => {
                  return (
                    <div
                      onPointerDown={(e) => {
                        e.preventDefault()
                        handleChange(item)
                        setSelected(true)
                      }}
                      key={key}
                      className='honest-option'
                    >
                      {item.label}
                    </div>
                  )
                })}
          </div>
        </Anchored>
      )}
      <article>{invalid && blured && <InvalidText>This field is required</InvalidText>}</article>
    </div>
  )
}
