/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useReducer, FC, ReactNode } from 'react'
import { Amplify } from 'aws-amplify'
import { amplifyConfig } from '../config'
import { IUser } from '../types/user.interface'
import { useDispatch, useSelector } from 'react-redux'
import { getMe } from '../features/user/userSlice'
import { AppDispatch, RootState } from '../store'
import { Spinner } from '../components/Loading/Loading'
import { setLoadingAuth } from 'features/global/globalSlice'
import { signIn, fetchAuthSession, fetchUserAttributes, SignInOutput, signOut, confirmSignIn } from 'aws-amplify/auth';

Amplify.configure(amplifyConfig)

interface State {
  isLoading: boolean
  isInitialized: boolean
  isAuthenticated: boolean
  user: IUser
  isAdmin: boolean
  isAdminOnLogin: boolean
}

interface AuthContextValue extends State {
  isLoading: boolean
  login: (phoneNumber: string) => Promise<SignInOutput>
  logout: () => Promise<void>
  verifyCode: (phoneNumber: string, code: string) => Promise<boolean>
}

interface AuthProviderProps {
  children: ReactNode
}

type InitializeAction = {
  type: 'INITIALIZE'
  payload: {
    isAuthenticated: boolean
    user: IUser | null
    isAdmin: boolean
  }
}

type LoginAction = {
  type: 'LOGIN'
  payload: {
    user: IUser
    isAdmin: boolean
    isAuthenticated: boolean
  }
}

type LogoutAction = {
  type: 'LOGOUT'
}

type VerifyCodeAction = {
  type: 'VERIFY_CODE'
}

type Action = InitializeAction | LoginAction | LogoutAction | VerifyCodeAction

const initialState: State = {
  isLoading: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isAdmin: false,
  isAdminOnLogin: null,
}

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, isAdmin } = action.payload
    return {
      ...state,
      isAuthenticated,
      isAdmin,
      isInitialized: true,
      user,
    }
  },

  LOGIN: (state: State, action: LoginAction): State => {
    const { user, isAdmin } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      isAdmin,
      user,
    }
  },

  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),

  VERIFY_CODE: (state: State, action: LoginAction): State => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
    }
  },
}

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve({} as SignInOutput),
  logout: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(false),
})


type CustomUserAttributes = {
  sub: string;
  phone_number?: string;
  getImagePresignedUrl?: string;
  attributes: {
    phone_number?: string;
  }
  // add other potential attributes
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)
  const dispatchRedux = useDispatch<AppDispatch>()
  const { user } = useSelector((store: RootState) => store.user)
  const userIsLoading = useSelector((state: RootState) => state.user.isLoading)

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        dispatchRedux(getMe())
        const userAttributes = await fetchUserAttributes() as unknown as CustomUserAttributes;

        const groups = await fetchAuthSession().then(
          session => session.tokens?.idToken?.payload['cognito:groups']
        );

        //  @@ts-ignore
        const isItAdmin = (groups as any)?.includes('admin')

        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            isAdmin: isItAdmin,
            user: {
              sub: userAttributes.sub,
              getImagePresignedUrl: userAttributes.getImagePresignedUrl,
              contact: {
                phoneNumber: userAttributes.phone_number,
              },
            },
          },
        })
      } catch (error) {
        console.log(error)
        // console.error(error)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            isAdmin: false,
          },
        })
      }
    }

    initialize()
  }, [])

  const login = async (phoneNumber: string): Promise<SignInOutput> => {
    dispatchRedux(setLoadingAuth(true))

    const signInUser = await signIn({ username: phoneNumber })

    dispatchRedux(setLoadingAuth(false))

    return signInUser
  }

  const logout = async (): Promise<void> => {
    await signOut()
    window.location.href = '/'
    dispatch({
      type: 'LOGOUT',
    })
  }

  const verifyCode = async (user: string, code: string): Promise<boolean> => {
    try {
      await confirmSignIn({ challengeResponse: code });

      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();


      const groups = session.tokens?.idToken?.payload['cognito:groups'] as string[];
      const isItAdmin = groups?.includes('admin') ?? false;

      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          isAdmin: isItAdmin,
          user: {
            getImagePresignedUrl: session.tokens?.idToken?.toString(), // You'll need to implement a new way to get presigned URLs
            sub: userAttributes.sub,
            contact: {
              phoneNumber: userAttributes.phone_number,
            },
          },
        },
      });

      dispatchRedux(getMe());
      return isItAdmin;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  if (userIsLoading) {
    return <Spinner loading={true} />
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        user: user,
        login,
        logout,
        verifyCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}


export default AuthContext
