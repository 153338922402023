import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { ICategorySingleRespone } from 'types/api/categoriesApi.interface'
import { ICategoriesSlice } from 'types/categories.interface'
import { IField } from 'types/fields.interface'
import { baseUrl } from '../../utils/axios'
import { dataURItoBlob, handleErrors } from '../../utils/helpers'

const initialState:ICategoriesSlice = {
  categories: [],
  isLoading: false,
  isLoadingSingle: false,
  category: null,
  noCategories: false,
  noCategoryFields: false,
}

export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async (filter: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<ICategorySingleRespone[]> = await baseUrl.get('categories' + (filter ? filter : ''))
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleCategory = createAsyncThunk(
  'categories/getSingleCategory',
  async (id: number, thunkAPI) => {
    try {
      const resp:AxiosResponse<ICategorySingleRespone> = await baseUrl.get('categories/' + id)
      return {...resp.data.category, image:resp.data.getImagePresignedUrl}
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addCategory = createAsyncThunk(
  'categories/addCategories',
  async (category: any, thunkAPI) => {
    try {
      const resp = await baseUrl.post(
        'categories',
        { name: category.name, image: category.image ? true : false },
        thunkAPI,
      )

      const url = resp.data.putImagePresignedUrl
      if (category.image.length > 1) {
        const blobedImage = dataURItoBlob(category.image)
        try {
          resp.data.getImagePresignedUrl = category.image
          await axios.put(url, blobedImage)
          return resp.data
        } catch (error) {
          return error
        }
      }

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async (category: any, thunkAPI) => {
    try {
      const { id } = category
      const resp = await baseUrl.put(
        'categories/' + id,
        {
          name: category.name,
          fields:
            (!category.fields.some((field:IField) => field.id)
              ? category.fields
              : category.fields.map((item:IField) => item.id)) || [],
          hasImage: category.image ? true : false,
        },
        thunkAPI,
      )
      if (category.image) {
        const url = resp.data.putImagePresignedUrl
        const blobedImage = dataURItoBlob(category.image)

        try {
          await axios.put(url, blobedImage)
        } catch (error) {
          return error
        }
      }
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('categories/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    clearCategory: (state: any) => {
      state.category = null
    },
    clearCategories: (state: any) => {
      state.categories = []
    },
  },
  extraReducers: {
    [getCategories.pending.type]: (state) => {
      state.isLoading = true
    },
    [getCategories.fulfilled.type]: (state: ICategoriesSlice, { payload }:{payload:ICategorySingleRespone[]}) => {
      state.isLoading = false
      state.categories = payload.map(c => ({
        ...c.category,
        image: c.getImagePresignedUrl,
        fields: c.category.fields.map(field => ({
          ...field,
          dropDownItems: field.dropDownItems.map(item => ({
            ...item,
            id: item.id.toString()
          }))
        }))
      }))
      state.noCategories = state.categories.length < 1
    },
    [getCategories.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },

    [getSingleCategory.pending.type]: (state) => {
      state.isLoadingSingle = true
    },
    [getSingleCategory.fulfilled.type]: (state: ICategoriesSlice, { payload }) => {
      state.category = payload
      state.isLoadingSingle = false
      state.noCategoryFields = state?.category?.fields?.length < 1
    },
    [getSingleCategory.rejected.type]: (state, { payload }) => {
      state.isLoadingSingle = false
      handleErrors(payload)
    },
    [addCategory.pending.type]: (state) => {
      state.isLoading = true
    },
    [addCategory.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.categories = [...state.categories, { ...payload.category, image:payload.getImagePresignedUrl }]
      state.noCategories = false
      toast.success('Category added')
    },
    [addCategory.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },

    [updateCategory.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateCategory.fulfilled.type]: (state, { payload }) => {
      const catIndex = state.categories.findIndex(
        (category) => category.id == payload.category.id,
      )
      state.noCategoryFields = payload.category.fields < 1
      state.categories[catIndex] = {...payload.category, image:payload.getImagePresignedUrl}
      state.isLoading = false
      toast.success('Category updated')
    },
    [updateCategory.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },

    [deleteCategory.pending.type]: (state) => {
      state.isLoading = true
    },

    [deleteCategory.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.categories = state.categories.filter((category) => category.id !== payload)
      toast.success('Category deleted')
    },
    [deleteCategory.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearCategory, clearCategories } = categoriesSlice.actions

export default categoriesSlice.reducer
