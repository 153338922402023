import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'

const useQueryPagination = ({ fetchFunctions,fetchFunctionsNoParams ,cleanUpFunctions, params, functionParams, otherFunctions }: IUseQueryPagination) => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    const queryString = new URLSearchParams(params).toString()
    window.history.replaceState(null, '', '?' + queryString)

    const fetchData = async () => {
      for (const f of fetchFunctions) {
        await dispatch(f(functionParams ?? params))
      }

      if(fetchFunctionsNoParams){
        for (const f of fetchFunctionsNoParams) {
          await dispatch(f())
        }
      }

      if(otherFunctions){
        for (const f of otherFunctions) {
        f()
       }
      }


    }
    fetchData()

    return () => {
      for (const f of cleanUpFunctions) {
        dispatch(f())
      }
    }
  }, [params])
}

export default useQueryPagination

interface IUseQueryPagination {
  fetchFunctions: any[]
  fetchFunctionsNoParams?: any[]
  cleanUpFunctions: any[]
  params: any
  functionParams?:any
  otherFunctions?:any[]
}
