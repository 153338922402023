import React, { useEffect, useState } from 'react'
import {
  Button,
  CardsContainer,
  Divider,
  MainHeadline,
  Table,
  Tr,
  Th,
  PageWrapper,
  PageFooter,
  Separator,
  PageControls,
} from '../../../styling/GlobalStyles'
import { Td } from 'components/Td/Td'
import { useNavigate } from 'react-router-dom'
import { NavBar } from '../../../components/NavBar/NavBar'
import { OrangeChevron } from '../../../assets/icons/OrangeChevron'
import { OrangeInfo } from '../../../assets/icons/OrangeInfo'
import { OrangePending } from '../../../assets/icons/OrangePending'
import { Options } from 'components/Options/Options'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearInventoryItemsList,
  deleteInventoryItem,
  getInfoIntentoryItems,
  getInventoryItems,
} from 'features/inventory/inventoryItemsSlice'
import { AppDispatch, RootState } from 'store'
import { Pagination } from 'components/Pagination/Pagination'
import { setConfirmModal } from 'features/global/globalSlice'
import { getParams, handlePagination } from 'utils/helpers'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { ModalTypes } from 'types/global.interface'
import { TdTextButton } from 'components/Td/Td.styled'
import { Modal } from 'components/Modal/Modal'
import { InventoryLocationsModal } from 'components/Modal/InventoryLocationsModal'
import useQueryPagination from 'hooks/useQueryPagination'

export const InventoryItems = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { inventoryItems, totalPages, inventoryItemsInfo, noItems } = useSelector(
    (store: RootState) => store.inventoryItems,
  )
  const navigate = useNavigate()
  const [params, setParams] = useState<any>(getParams({ location, limit: 5 }))
  const [modal, setModal] = useState({ isOpen: false, locations: [], itemName: null })
  console.log(inventoryItemsInfo)
  const boxItems = [
    {
      id: 1,
      text: 'Total Items',
      number: inventoryItemsInfo?.total,
      icon: OrangeChevron,
    },
    {
      id: 2,
      text: 'Out Of Stock',
      number: inventoryItemsInfo?.outOfStock,
      icon: OrangeInfo,
    },
    {
      id: 3,
      text: 'Low Stock',
      number: inventoryItemsInfo?.lowStock,
      icon: OrangeInfo,
    },
    {
      id: 4,
      text: 'Pending Order',
      number: 0,
      icon: OrangePending,
    },
  ]

  useQueryPagination({ fetchFunctions: [getInventoryItems], cleanUpFunctions: [clearInventoryItemsList], params })

  useEffect(() => {
    dispatch(getInfoIntentoryItems())
  }, [])

  const handleDeleteItem = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteInventoryItem(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: inventoryItems.length < 2,
        },
        word: 'delete item',
      }),
    )
  }

  return (
    <>
      <NavBar
        defaultValue={params.q}
        input
        placeholder='Search Items...'
        setParams={setParams}
        limit={5}
      />
      <PageWrapper>
        <PageControls>
          <MainHeadline>Inventory Items</MainHeadline>
          <Button orange onClick={() => navigate('add')}>
            Add Item
          </Button>
        </PageControls>
        <Separator />

        <CardsContainer>
          {boxItems.map((item, key) => {
            const { text, number, icon } = item
            return (
              <div key={key}>
                <h3>{text}</h3>
                <section>
                  <article>{icon}</article>
                  <span>{number}</span>
                </section>
              </div>
            )
          })}
        </CardsContainer>
        <Divider mb='3' />
        {noItems ? (
          <NoItems title='Inventory Items' />
        ) : (
          <Table>
            <Th>
              <Td w='10%' noTooltip text='SKU' />
              <Td w='20%' noTooltip text='Item Name' />
              <Td w='10%' noTooltip text='Model' />
              <Td w='10%' noTooltip text='QTY' />
              <Td w='20%' noTooltip text='Category' />
              <Td w='15%' noTooltip text='Min' />
              <Td w='15%' noTooltip text='Max' />
              <Td w='15%' noTooltip text='Locations' />
              <Td w='5%'></Td>
            </Th>
            {inventoryItems &&
              inventoryItems.map((item, key: number) => {
                return (
                  <Tr key={key}>
                    <Td w='10%' text={item.sku} />
                    <Td w='20%' text={item.name} />
                    <Td w='10%' text={item.model} />
                    <Td w='10%' text={item.quantity} />
                    <Td w='20%' text={item?.category?.name} />
                    <Td w='15%' text={item.minLimit} />
                    <Td w='15%' text={item.maxLimit} />
                    {item?.currentLocations?.length > 0 ? (
                      <Td w='15%'>
                        <TdTextButton
                          onClick={() =>
                            setModal({ isOpen: true, locations: item.currentLocations, itemName: item.name })
                          }
                        >
                          View locations
                        </TdTextButton>
                      </Td>
                    ) : (
                      <Td w='15%' text={item?.location.name} />
                    )}
                    <Td noTooltip w='5%'>
                      <Options
                        item={item}
                        handleRemove={() => handleDeleteItem(item.id)}
                        title='Inventory Item'
                      />
                    </Td>
                  </Tr>
                )
              })}
          </Table>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 5)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
      {modal.isOpen && (
        <Modal black>
          <InventoryLocationsModal locations={modal.locations} setModal={setModal} itemName={modal.itemName} />
        </Modal>
      )}
    </>
  )
}
