import React, { useState } from 'react'
import { InputWrapper, Label } from 'components/Input/Input.styled'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './datePicker.css'
import { InvalidText } from 'styling/GlobalStyles'
import { handleFocusNext, months } from 'utils/helpers'

const DatePickerHeader = ({
  date,
  changeMonth,
  changeYear,
  decreaseMonth,
  increaseMonth,
  minDate,
  maxDate,
}: {
  date: Date
  changeMonth: any
  changeYear: any
  decreaseMonth: any
  increaseMonth: any
  minDate?: any
  maxDate?: any
}) => {
  const getYears = () => {
    let ar = []

    if (!maxDate && !minDate) {
      ar = Array.from({ length: 80 }, (x, i) => i + new Date().getFullYear() - 40)
    } else if (!maxDate && minDate) {
      ar = Array.from({ length: 80 }, (x, i) => i + minDate.getFullYear())
    } else if (maxDate && !minDate) {
      ar = Array.from({ length: 80 }, (x, i) => i + maxDate.getFullYear() - 79)
    }

    return ar.map((y: number, k: number) => (
      <option
        value={y}
        key={k}
      // selected={
      //   date ? y == new Date(date).getFullYear() :
      //     ar.some((i) => i === new Date().getFullYear()) ? y === new Date().getFullYear() : k === 79
      // }
      >
        {y}
      </option>
    ))
  }
  return (
    <div className='datePickerHeaderContainer' onKeyDown={handleFocusNext}>
      <button
        aria-label='Previous Month'
        className={'react-datepicker__navigation react-datepicker__navigation--previous'}
        onClick={decreaseMonth}
      >
        <span
          className={
            'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
          }
        >
          {'<'}
        </span>
      </button>
      <h3>{date.getDate() + '. ' + months[date.getMonth()] + ' ' + date.getFullYear() + '.'}</h3>
      <div className='datePickerHeaderSelects'>
        <select
          value={months[date.getMonth()]}
          onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        >
          {months.map((m: string, k: number) => (
            <option value={m} key={k}>
              {m}
            </option>
          ))}
        </select>
        <select
          // value={date ? date.getFullYear() : null}
          onChange={({ target: { value } }) => changeYear(value)}
          value={date ? date.getFullYear() : new Date().getFullYear()}
        >
          {getYears()}
        </select>
      </div>
      <button
        aria-label='Next Month'
        className={'react-datepicker__navigation react-datepicker__navigation--next'}
        onClick={increaseMonth}
      >
        <span
          className={'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'}
        >
          {'>'}
        </span>
      </button>
    </div>
  )
}

export const DatePickerInput = ({
  label,
  date,
  setDate,
  minDate,
  maxDate,
  onChange,
  name,
  noInvalidText,
}: IDatePickerInput) => {
  const [blured, setBlured] = useState<boolean>(false)

  let cName = 'date-picker-active'

  if (!date) {
    cName = 'date-picker-empty'
  }

  if (blured && !date) {
    cName += ' date-invalid'
  }

  return (
    <InputWrapper style={{ display: 'flex', flexDirection: 'column' }} >
      <Label labelText={label} />
      <DatePicker
        onKeyDown={handleFocusNext}
        selected={date}
        className={cName}
        minDate={minDate}
        onBlur={() => setBlured(true)}
        maxDate={maxDate}
        showYearDropdown={true}
        showMonthDropdown={true}
        dateFormat='dd. MM. yyyy.'
        placeholderText={'Enter date'}
        onChange={(dt) => (onChange ? onChange(dt, name) : setDate(dt))}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          // prevMonthButtonDisabled,
          // nextMonthButtonDisabled,
        }) => (
          <DatePickerHeader
            decreaseMonth={decreaseMonth}
            date={date}
            changeMonth={changeMonth}
            changeYear={changeYear}
            minDate={minDate}
            maxDate={maxDate}
            increaseMonth={increaseMonth}
          />
        )}
      />
      {blured && !date && !noInvalidText && <InvalidText>This field is required</InvalidText>}
    </InputWrapper>
  )
}

interface IDatePickerInput {
  label: string
  date: Date
  setDate?: React.Dispatch<React.SetStateAction<any>>
  maxDate?: Date
  minDate?: Date
  onChange?: any
  name?: string
  noInvalidText?: boolean
}
