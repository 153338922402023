import { CloseIcon } from 'assets/icons/CloseIcon'
import { WidgetsSelectItem, WidgetsSidebarContainer } from 'pages/Dashboard/Dashboard.styled'
import { widgetsList } from 'pages/Dashboard/dashboardData'
import { Flex, P, SquareIcon } from 'styling/GlobalStyles'

export const WidgetsSidebar = ({ open, setOpen, setDragging, presentWidgets, saveLayout }: IWidgetsSidebar) => {
    const widgets = widgetsList.filter(w => !presentWidgets.includes(w.type))
    return <WidgetsSidebarContainer style={open ? { minWidth: '350px', padding: '1rem', opacity: 1 } : { width: '0', minWidth: '0', padding: '0', opacity: 0, overflow: 'auto' }}  >
        <Flex width='100%' between >
            <P>Choose widgets</P>
            <CloseIcon onClick={() => {
                saveLayout()
                setOpen(false)
            }} />
        </Flex>
        {
            // eslint-disable-next-line react/jsx-key
            widgets.length ? widgets.map(w => <WidgetsSelectItem
                key={w.title}
                draggable
                onDragStart={() => setDragging(prev => ({ ...prev, width: w.width, type: w.type }))}
                onDragEnd={() => setDragging({ width: 0, type: '', overArea: '' })}
            >
                <SquareIcon color={'#ff9900'} >
                    {w.icon}
                </SquareIcon>
                <div className='text' >
                    <h5>
                        {w.title}
                    </h5>
                    <P>{w.desc}</P>
                </div>
            </WidgetsSelectItem>) : <p style={{ fontSize: '.8rem', color: 'grey', marginTop: '2rem' }} >All widgets are being used</p>
        }

    </WidgetsSidebarContainer>
}

interface IWidgetsSidebar {
    presentWidgets: string[]
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    setDragging: React.Dispatch<React.SetStateAction<{
        width: number;
        type: string;
        overArea: string
    }>>
    saveLayout: () => void
}