import axios from 'axios'
import {fetchAuthSession } from 'aws-amplify/auth'

export const axiosRequestInterceptor = async (config) => {
  const token =  (await fetchAuthSession()).tokens.accessToken.toString()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

export const baseUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  headers: {
    'Content-Type': 'application/json',
    'tz':Intl.DateTimeFormat().resolvedOptions().timeZone
  },
})

export const unbaseUrl = axios.create({
  baseURL: `${process.env.REACT_APP_UNAPI}`,
  headers: {
    'Content-Type': 'application/json',
    'tz':Intl.DateTimeFormat().resolvedOptions().timeZone

  },
})

export const authUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_AUTH}`,
  headers: {
    'Content-Type': 'application/json',
    'tz':Intl.DateTimeFormat().resolvedOptions().timeZone

  },
})

export const imageUrl = async (url, image) => {
  const token =  (await fetchAuthSession()).tokens.accessToken.toString()

  const formData = new FormData()
  formData.append('img', image)

  return fetch(process.env.REACT_APP_API + url, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'tz':Intl.DateTimeFormat().resolvedOptions().timeZone

    },
    body: formData,
  })
}

baseUrl.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e))
unbaseUrl.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e))
authUrl.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e))
