/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { machineryIcon } from 'assets/icons/MachineryIcon'
import { Title } from 'components/Dashboard/Title'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import { RootState } from 'store'
import { DashboardCard, DCBarTooltip, DCContent, MachineryCardCount, P } from 'styling/GlobalStyles'
import { machineryStatusesData } from '../dashboardData'
import { WidgetEditOverlay } from '../Dashboard.styled'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { IWidget } from 'types/dashboard.interface'

export const MachineryWidget = ({
    modalOpen,
    removeWidget,
    onDragOver,
    onDragStart,
    onDragDrop
}: IWidget) => {
    const navigate = useNavigate()
    const { machinery } = useSelector((state: RootState) => state.dashboard)
    const machineryData =
        machinery?.machineStatuses.length > 0
            ? machineryStatusesData.map((m) => {
                if (machinery?.machineStatuses.some((s) => s.status == m.id)) {
                    const foundStatus = machinery?.machineStatuses.find((ms) => ms.status == m.id)
                    return {
                        name: machineryStatusesData.find((msd) => msd.id == foundStatus.status).name,
                        value: foundStatus.count,
                    }
                }
                return { name: m.name, value: 0.1 }
            })
            : machineryStatusesData.map((msd) => ({ name: msd.name, value: 0 }))
    const colors = ['#00a368', '#ff9900', '#ff5b5b', '#b46f33', '#30b6d1', '#7f0788', '#3c8f05333']

    return (
        <DashboardCard
            draggable={modalOpen}
            onDragStartCapture={() => {
                onDragStart('machinery', 1)
            }}
            onDrop={onDragDrop}
            onDragEnd={(e) => {
                e.preventDefault()
            }}
            onDragOver={(e) => {
                e.preventDefault()
                onDragOver('machinery')
            }}
            width='1fr'
            style={{ gridArea: 'machinery', cursor: modalOpen ? 'grab' : 'normal' }}
        >
            {modalOpen && (
                <WidgetEditOverlay
                    onClick={(e) => {
                        removeWidget('machinery')
                        e.stopPropagation()
                    }}
                >
                    <P>Remove Widget</P>
                    <CloseIcon white />
                </WidgetEditOverlay>
            )}
            <Title
                mainIcon={machineryIcon}
                title='Machinery'
                subtitle='All statuses'
                arrow
                onClick={() => !modalOpen && navigate('/dashboard/machines')}
            />
            <DCContent>
                <MachineryCardCount>
                    <p>Total Machinery</p>
                    <h3>{machinery?.totalCount}</h3>
                </MachineryCardCount>
                <ResponsiveContainer width='100%' height='80%'>
                    <BarChart data={machineryData} barSize={20}>
                        <Tooltip
                            wrapperStyle={{ outline: 'none' }}
                            content={(props) => {
                                return (
                                    props.payload && (
                                        <DCBarTooltip>
                                            <p>{props?.payload[0]?.payload?.name}</p>
                                            {/* @ts-ignore */}
                                            <p>{props?.payload[0]?.value >= 1 ? props?.payload[0]?.value : 0}</p>
                                        </DCBarTooltip>
                                    )
                                )
                            }}
                        />
                        <CartesianGrid strokeOpacity={0.7} strokeDasharray='3 3' />
                        <Bar dataKey='value' radius={5}>
                            {machineryData?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </DCContent>
        </DashboardCard>
    )
}
