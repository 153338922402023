/* eslint-disable @typescript-eslint/no-empty-function */
import { AlertIcon } from 'assets/icons/AlertIcon'
import { Title } from 'components/Dashboard/Title'
import { AlertCard, DashboardCard, DCContent, Flex, P } from 'styling/GlobalStyles'
import { dashboardAlertCards } from '../dashboardData'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { AlertsModal } from '../AlertsModal'
import { useState } from 'react'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { WidgetEditOverlay } from '../Dashboard.styled'
import { IWidget } from 'types/dashboard.interface'
import { createPortal } from 'react-dom'

export const AlertsWidget = ({
    modalOpen,
    removeWidget,
    onDragStart,
    onDragOver,
    onDragDrop,
}: IWidget) => {
    const { alerts } = useSelector((state: RootState) => state.dashboard)
    const [alertsModal, setAlertsModal] = useState({ modalOpen: false, alertId: null, total: null })

    const AlertCardComponent = ({ count, title, onClick }: IAlertCardComponent) => {
        return (
            <AlertCard onClick={onClick}>
                <Flex column between height='100%'>
                    <h1>{count}</h1>
                    <p>{title}</p>
                </Flex>
                <span>{ArrowLeft}</span>
            </AlertCard>
        )
    }

    return (
        <DashboardCard
            draggable={modalOpen}
            onDragStartCapture={() => {
                onDragStart('alerts', 2)
            }}
            onDrop={onDragDrop}
            onDragEnd={(e) => {
                e.preventDefault()
            }}
            onDragOver={(e) => {
                e.preventDefault()
                onDragOver('alerts')
            }}
            width='1fr'
            style={{ gridArea: 'alerts', cursor: modalOpen ? 'grab' : 'normal' }}
        >
            {modalOpen && (
                <WidgetEditOverlay
                    onClick={(e) => {
                        e.stopPropagation()
                        removeWidget('alerts')
                    }}
                >
                    <P>Remove Widget</P>
                    <CloseIcon white />
                </WidgetEditOverlay>
            )}
            <Title mainIcon={AlertIcon} title='Alerts' subtitle='All statuses' color='#FF5B5B' />
            <DCContent grid>
                {dashboardAlertCards.map((c, i) => (
                    <AlertCardComponent
                        key={i}
                        title={c.name}
                        count={alerts && alerts[c.prop] ? alerts[c.prop] : 0}
                        onClick={() =>
                            !modalOpen &&
                            !alertsModal.modalOpen &&
                            setAlertsModal({
                                modalOpen: true,
                                alertId: c.name,
                                total: alerts && alerts[c.prop] ? alerts[c.prop] : 0,
                            })
                        }
                    />
                ))}
            </DCContent>
            {alertsModal.modalOpen &&
                createPortal(<AlertsModal modal={alertsModal} setModal={setAlertsModal} />, document.body)}
        </DashboardCard>
    )
}

interface IAlertCardComponent {
    count: number
    title: string
    warning?: boolean
    onClick: () => void
}
